import { Event } from '@material-ui/icons';
import { IModule } from './interfaces'

export const modules: IModule[] = [
  {
    name: 'Citas médicas',
    description: 'Gestionar y solicitar tus citas para tu Unidad de Medicina Familiar correspondiente.',
    to: '/citas-medicas',
    icon: <Event fontSize='small' />,
    show: true
  }
];