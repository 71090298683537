import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, Checkbox, CircularProgress, Container, createStyles, FormControlLabel, FormGroup, Grid, makeStyles, TextField, Theme, Typography, useTheme } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { getCentroMedico, login } from '../utils/services';
import { APP_NAME, ESCALA_GRIS } from '../utils/constants';
import { toast } from 'react-toastify';
import { Redirect, useHistory } from 'react-router';
import { checkValidLogin, decodeToken, encodeQueryData } from '../utils/functions';
import Icon from '@mdi/react';
import { mdiFacebook, mdiTwitter, mdiYoutube } from '@mdi/js';
import { blue, red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    height: '100vh',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3)
  },
  logo: {
    maxWidth: 160,
    marginTop: 30
  },
  logoPhone: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
    '& img': {
      maxWidth: 125
    }
  },
  // logoMedicina: {
  //   width: '100%',
  //   maxWidth: 650,
  //   textAlign: 'center'
  // },
  // oneColumn: {
  //   display: 'none',
  //   [theme.breakpoints.up('md')]: {
  //     display: 'flex'
  //   }
  // },
  groupParrafo: {
    '& span, & a': {
      fontWeight: 'bold',
      color: theme.palette.primary.main
    }
  }
}));

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveLogin, setSaveLogin] = useState(false);
  const [recaptchaSuccess, setRecaptchaSuccess] = useState(false);
  const [data, setData] = useState({ NOMBRE_COMPLETO: '', NUMERO_CUENTA: 0, RFC: '' });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setError(false);
      setLoading(true);

      if (!recaptchaSuccess && process.env.REACT_APP_ENV === 'production') return toast.error('Debe completar el captcha.');

      if (!data.NOMBRE_COMPLETO.trim() || !data.NUMERO_CUENTA || !data.RFC.trim()) return setError(true);

      if (data.RFC.trim().length < 10) return toast.error('El RFC debe ser mínimo de 10 dígitos.');

      const token = await login(data);
      if (!token || !token) return;
      saveLogin ? localStorage.setItem('token', token) : sessionStorage.setItem('token', token);

      const derechohabiente = decodeToken(token);
      if (!derechohabiente || !derechohabiente.ID_SERVICIO_MEDICO) return toast.error('El derechohabiente tiene datos incompletos. Favor de comunicarse a ISSETel.');

      const infoCentroMedico = encodeQueryData({
        equal: true,
        ID_SERVICIO_MEDICO: derechohabiente.ID_SERVICIO_MEDICO,
        ID_CENTRO_MEDICO: derechohabiente.ID_SERVICIO_MEDICO === 4 ? 1 : null
      });
      const centroMedico = await getCentroMedico(infoCentroMedico);

      saveLogin ? localStorage.setItem('centroMedico', JSON.stringify(centroMedico[0])) : sessionStorage.setItem('centroMedico', JSON.stringify(centroMedico[0]));

      history.push('/');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  if (checkValidLogin()) return <Redirect to='/' />

  return (
    <Container style={{ height: '100vh', maxWidth: 2000 }}>
      <Helmet>
        <title>{APP_NAME} | Iniciar sesión </title>
      </Helmet>

      <Grid  container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '70vh' }}>
        <Grid /*className={classes.oneColumn} container  justify='space-between' */ item xs={9}>
          <Grid item xs={12}>
            <a href="http://www.isset.gob.mx/" target='_blank' rel='nopener noreferrer'>
              <img className={classes.logo} src={require('../assets/images/logo-isset-negro.png').default} alt='Logo ISSET' />
            </a>
            <Grid item xs={12} container spacing={1}>
              <Typography style={{ marginTop: 20 }}variant='h2'>Portal de citas médicas</Typography>
            </Grid>
          </Grid>

          <Grid item>
              {/* <Typography variant='body1' color='textSecondary'>Por favor ingrese los datos que se solicitan para tener acceso a los diferentes módulos de prestaciones médicas que ofrece el ISSET a sus derechohabientes.</Typography> */}
              <Typography color='error' style={{ marginTop: 20 }} variant='body1' /*variant='caption'*/ component='p'><b>Nota:</b> Si el dispositivo desde el que accede es de uso público, sugerimos cerrar la sesión cuando finalice de realizar sus trámites.</Typography>
          </Grid>

          <Grid item xs={12}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} style={{ width: '100%' }}>
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                  <Typography color='textSecondary' variant='caption'>*Campos obligatorios</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    size='small'
                    color='primary'
                    label='Nombre completo*'
                    variant='outlined'
                    value={data.NOMBRE_COMPLETO}
                    error={error && !data.NOMBRE_COMPLETO}
                    helperText={error && !data.NOMBRE_COMPLETO ? 'Campo obligatorio' : 'Utilice el formato: nombre(s) + apellido paterno + apellido materno'}
                    onChange={e => setData({ ...data, NOMBRE_COMPLETO: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    size='small'
                    color='primary'
                    label='Número de cuenta*'
                    variant='outlined'
                    value={data.NUMERO_CUENTA || 0}
                    error={error && !data.NUMERO_CUENTA}
                    helperText={error && !data.NUMERO_CUENTA ? 'Campo obligatorio' : ''}
                    onChange={e => setData({ ...data, NUMERO_CUENTA: parseInt(e.target.value) })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    size='small'
                    color='primary'
                    label='RFC (con o sin homoclave)*'
                    variant='outlined'
                    value={data.RFC}
                    error={error && !data.RFC}
                    helperText={error && !data.RFC ? 'Campo obligatorio' : ''}
                    onChange={e => setData({ ...data, RFC: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <FormControlLabel
                      label='Recordar datos en este dispositivo'
                      control={
                        <Checkbox
                          color='primary'
                          name='saveLogin'
                          checked={saveLogin}
                          onChange={e => setSaveLogin(e.target.checked)}
                        />
                      }
                    />
                  </FormGroup>
                </Grid>
                {process.env.REACT_APP_ENV === 'production' ? <Grid item xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ReCAPTCHA
                      sitekey='6LeOqoMaAAAAADeDhLavEqh6AadvkftgjEkYHJKr'
                      onChange={value => setRecaptchaSuccess(value ? true : false)}
                      onExpired={() => setRecaptchaSuccess(false)}
                    />
                  </div>
                </Grid> : null}
                <Grid item xs={12} style={{ marginTop: 3 }}>
                  <Button
                    fullWidth
                    type='submit'
                    disabled={loading}
                    color='primary'
                    variant='contained'
                    startIcon={loading ? <CircularProgress size={20} color='inherit' /> : null}
                  >
                    {!loading ? 'Buscar datos' : 'Buscando'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>

          <Grid className={classes.groupParrafo} item xs={12} container direction='column' spacing={3}>
            <Grid item>
              <Typography variant='body1' style={{marginTop: 20}}>Si sospechas que tus síntomas son indicativos de COVID, <span>NO ES NECESARIO</span> que solicites una cita en línea. Preséntate directamente en las Unidades de Atención a Enfermedades Respiratorias (<a href="http://www.isset.gob.mx/servicios-medicos/domicilios-umfs" target='_blank' rel='noopener noreferrer'>consulta aquí el domicilio</a>), o en tu Unidad de Medicina Familiar municipal, para recibir atención y valoración.</Typography>
            </Grid>
            <Grid item>
              <Typography variant='body1' style={{marginTop: 10}}>Puedes comunicarte a la línea <span>ISSETel al 8000 909090</span> para dudas, comentarios o aclaraciones.</Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ marginTop: 'auto' }} container>
            <Grid item xs={6}>
              <Typography color='textSecondary' variant='caption' component='p' style={{marginTop: 20}}>Siguenos en</Typography>
              <div>
                <a href="https://www.facebook.com/issetTabasco" target="_blank" rel="noopener noreferrer">
                  <Icon path={mdiFacebook} color={ESCALA_GRIS ? theme.palette.primary.main : blue[800]} size={1.05} style={{ marginRight: 10, opacity: 0.7 }} />
                </a>
                <a href="https://twitter.com/Isset_Tab" target="_blank" rel="noopener noreferrer">
                  <Icon path={mdiTwitter} color={ESCALA_GRIS ? theme.palette.primary.main : blue[500]} size={1.05} style={{ marginRight: 10, opacity: 0.7 }} />
                </a>
                <a href="https://www.youtube.com/channel/UCl6tcQE_bnIRDQz35ASH69Q" target="_blank" rel="noopener noreferrer">
                  <Icon path={mdiYoutube} color={ESCALA_GRIS ? theme.palette.primary.main : red[400]} size={1.05} style={{ marginRight: 10, opacity: 0.7 }} />
                </a>
              </div>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right', marginTop: 20}}>
              <Typography color='textSecondary' variant='caption' component='a' href='http://presmed.isset.gob.mx:9005/static/Guia-de-uso-20230210.pdf' target='_blank' rel='noopener noreferrer'>Guía de uso del portal</Typography>
              <br />
              <Typography color='textSecondary' variant='caption' component='a' href='http://www.isset.gob.mx/static/docs/transparencia-difusion/4_aviso-privacidad.pdf' target='_blank' rel='noopener noreferrer'>Aviso de privacidad</Typography>
            </Grid>
          </Grid>

          {/* <Grid item xs={12}>
            <Typography variant='h3' component='p' color='primary' style={{ marginBottom: 30 }}>Bienvenido(a)</Typography>

            <Typography variant='h6' component='p'>Aquí podrá tener acceso a los diferentes servicios en línea de prestaciones médicas que el ISSET ofrece a sus derechohabientes.</Typography>
          </Grid>

          <Grid item xs={12}>
            <img className={classes.logoMedicina} style={{ width: '100%' }} src={ESCALA_GRIS ? require('../assets/images/medicine-gray.svg').default : require('../assets/images/medicine-color.svg').default} alt='Medicina Logo' />
          </Grid> */}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;