import { AXIOS_SIPEM } from "../../utils/constants";
import { handleAxiosError } from "../../utils/functions";
import { IConstancia } from "./interfaces";

export const getConstancias = async (folio: number, cuenta: number) => {
  try {
    const url = `/constancias/${folio}/constancias-menor/${cuenta}`;
    const resp = await AXIOS_SIPEM.get(url, {
      headers: {
        'Authorization': sessionStorage.getItem('token')
      }
    });
    return resp.data as IConstancia;
  } catch (error) {
    handleAxiosError(error);
  }
}