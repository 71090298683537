import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { createStyles, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, Typography } from '@material-ui/core';
import { Close, Flag } from '@material-ui/icons';
import { yellow } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  closeButtonDialog: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 5,
    color: theme.palette.grey[500]
  }
}));

interface IHelpCardCita {
  open: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>
}

const HelpCardCita: FunctionComponent<IHelpCardCita> = ({ open, setOpen }) => {
  const classes = useStyles();

  const flags: { color: string, title: string, subtitle?: string }[] = [
    {
      color: '#9D2449',
      title: 'Indica que la cita fue agendada desde el sistema de prestaciones médicas.'
    },
    {
      color: '#757575',
      title: 'Indica que la cita fue agendada por la unidad médica.',
      subtitle: 'No puede ser cancelada por el derechohabiente.'
    },
    {
      color: yellow[800],
      title: 'Indica que la cita está asignada a una referencia.',
      subtitle: 'No puede ser cancelada por el derechohabiente.'
    }
  ]

  return (
    <Dialog maxWidth='md' open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Typography variant='h4' component='span'>Obtener ayuda</Typography>
        <IconButton className={classes.closeButtonDialog} onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers style={{ overflowX: 'hidden' }}>
        <DialogContentText>
          Al realizar la búsqueda de sus citas agendadas, podrá visualizar todas aquellas citas médicas que han sido registradas, ya sea por la unidad médica o desde el sistema de prestaciones médicas.
        </DialogContentText>
        <DialogContentText>
          Todas las tarjetas de las citas cuentan con la siguiente información:
        </DialogContentText>
        <div style={{ overflowX: 'auto', maxWidth: 840, margin: '0 auto' }}>
          <a href={require('../../../assets/images/help-lista-cita.png').default} target='_blank' rel='noopener noreferrer'>
            <img style={{ width: '100%' }} src={require('../../../assets/images/help-lista-cita.png').default} alt='Tarjeta de cita - Ayuda' />
          </a>
        </div>
        <DialogContentText>
          Los tipos de cita agendadas, son determinados por el color del icono de la bandera en la parte superior derecha.
        </DialogContentText>
        <List dense disablePadding>
          {flags.map((flag, index) => (
            <ListItem key={index} disableGutters>
              <ListItemIcon style={{ minWidth: 45 }}>
                <Flag style={{ color: flag.color }} />
              </ListItemIcon>
              <ListItemText primary={flag.title} secondary={flag?.subtitle} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default HelpCardCita;