import { createStyles, makeStyles, Switch, Theme, withStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  values: {
    width: '100%',
    display: 'grid',
    textAlign: 'center',
    gridTemplateColumns: '1fr repeat(2, 50px)',
    '& > *:last-child, & > *:nth-child(2)': {
      marginRight: 24
    }
  }
}));

export const PurpleSwitch = withStyles({
  switchBase: {
    color: '#9d2449',
    '&$checked': {
      color: '#9d2449',
    },
    '&$checked + $track': {
      backgroundColor: '#9d2449',
    },
  },
  checked: {},
  track: {},
})(Switch);