import React, { useState } from 'react';
import { useWindowSize } from 'react-use';
import { Stepper, Step, StepLabel, Typography, Button } from '@material-ui/core';
import TestRespiratorio from './TestRespiratorio';
import DisponibilidadGeneral from './DisponibilidadGeneral';
import CuestionarioSatisfaccion from './CuestionarioSatisfaccion';
import FichaCita from '../FichaCita';
import { ICitaMedica, IEspecialidad, ISintomaOnline } from '../interfaces';
import { useHistory } from 'react-router';

const NewCita = () => {
  const size = useWindowSize();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [resultadoTest, setResultadoTest] = useState(0);
  const [datosCita, setDatosCita] = useState<ICitaMedica>();
  const [sintomas, setSintomas] = useState<ISintomaOnline[]>([]);
  const [especialidades, setEspecialidades] = useState<IEspecialidad[]>([]);

  const nextStep = () => setActiveStep(activeStep + 1);
  const prevStep = () => setActiveStep(activeStep - 1);

  return (
    <>
      <Stepper alternativeLabel={size.width < 700} activeStep={activeStep} style={{ padding: 0, marginBottom: 24 }}>
        {/* <Step style={{ textAlign: size.width < 700 ? 'center' : 'left' }}>
          <StepLabel optional={<Typography variant='caption'>Ayúdanos a prevenir más contagios</Typography>}>Test respiratorio</StepLabel>
        </Step> */}

        <Step style={{ textAlign: size.width < 700 ? 'center' : 'left' }}>
          <StepLabel optional={<Typography variant='caption'>Ya casi. ¡Solo un poco más!</Typography>}>Consultar disponibilidad</StepLabel>
        </Step>

        <Step style={{ textAlign: size.width < 700 ? 'center' : 'left' }}>
          <StepLabel optional={<Typography variant='caption'>Datos de la cita médica</Typography>}>Verifica que todo sea correcto</StepLabel>
        </Step>
      </Stepper>

      {/* {activeStep === 0 ? (
        <TestRespiratorio
          sintomas={sintomas}
          setSintomas={setSintomas}
          setResultadoTest={setResultadoTest}
          setActiveStep={setActiveStep}
          nextStep={nextStep}
        />
      ) : null} */}
      {activeStep === 0 ? (
        <DisponibilidadGeneral
          especialidades={especialidades}
          setDatosCita={setDatosCita}
          setEspecialidades={setEspecialidades}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      ) : null}
      {activeStep === 1 ? (
        <>
          <CuestionarioSatisfaccion />

          <FichaCita
            showPreview
            sintomas={sintomas}
            datosCita={datosCita}
            resultadoTest={resultadoTest}
            finalButton={
              <Button
                color='primary'
                variant='contained'
                onClick={() => history.push('/citas-medicas')}
              >Finalizar</Button>
            }
          />
        </>
      ) : null}
    </>
  );
};

export default NewCita;