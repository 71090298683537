import { AXIOS_SIPEM, AXIOS_SIPEM_CENTRO } from "../../utils/constants";
import { checkValidLogin, encodeQueryData, getApiUrl, handleAxiosError } from "../../utils/functions";
import { ICitaMedica, IFichaCita, ICitaParams, IDiaNoLaborable, IAgendaEnLinea, ICasoEspecialLocal, IParametroOnline, ITestRespiratorio, IPortalParametroGlobal, ITipoTrabajadorAtencion, IConfiguracionAgenda, IMedicoActivo } from "./interfaces";
import { IReferenciaEspecialidad, IReferenciaVigente } from "./Referencias/interfaces";

export const getSintomasOnline = async () => {
  try {
    if (!checkValidLogin()) return;

    const url = `/sintomas-online`;
    const resp = await AXIOS_SIPEM.get(url, {
      headers: {
        'Authorization': localStorage.getItem('token') || sessionStorage.getItem('token')
      }
    });

    return resp.data;
  } catch (error) {
    handleAxiosError(error);
  }
}

export const getDisponibilidad = async (idsConfiguracionAgenda: number[], claveCentroMedico: string, fechaInicio: string, fechaFin?: string, idsTipoAtencion?: number[], filtrarPorAtencion?: boolean, isReferencia = false) => {
  try {
    if (!checkValidLogin()) return;

    const url = `${getApiUrl('sipem', claveCentroMedico === 'UMFC')}/disponibilidad-agenda?timestamp=${new Date().getTime()}`;
    const data = { idsConfiguracionAgenda, fechaInicio, fechaFin, idsTipoAtencion, filtrarPorAtencion, isReferencia };
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Centro-Medico': claveCentroMedico,
        'Authorization': localStorage.getItem('token') || sessionStorage.getItem('token') || '',
        'Control-Cache': 'no-cache'
      },
      body: JSON.stringify(data)
    });

    const info = await resp.json();
    return info;
  } catch (error) {
    handleAxiosError(error);
  }
}

export const getCitaConsultaGeneral = async (search: string, claveCentroMedico: string, params?: Partial<ICitaParams>) => {
  try {
    if (!checkValidLogin()) return;

    const url = `/citas-medicas/consulta-general?timestamp=${new Date().getTime()}&${search}`;
    const data = { ...params };
    const isCentro = claveCentroMedico === 'UMFC';
    const headers = {
      'Access-Centro-Medico': claveCentroMedico,
      'Authorization': localStorage.getItem('token') || sessionStorage.getItem('token')
    }
    const resp = isCentro ? await AXIOS_SIPEM_CENTRO.post(url, data, { headers }) : await AXIOS_SIPEM.post(url, data, { headers });

    return resp.data as ICitaMedica[];
  } catch (error) {
    handleAxiosError(error);
  }
}

export const getReferenciasVigentes = async (idPersonal: number, idBeneficiario: number, folioConsecutivo?: number) => {
  try {
    const query = encodeQueryData({ idPersonal, idPacienteBeneficiario: idBeneficiario, folioConsecutivo, reqCita: true, hasCita: false, conVigencia: true, timestamp: new Date().getTime() });
    const url = `/referencias/vigentes?${query}`;
    const headers = {
      'Authorization': localStorage.getItem('token') || sessionStorage.getItem('token'),
      'Cache-Control': 'no-cache'
    };
    const resp = await AXIOS_SIPEM.get(url, { headers });
    return resp.data as IReferenciaVigente[];
  } catch (error) {
    handleAxiosError(error);
  }
}

export const getCitasReferencias = async (search: string, claveCentroMedico: string, params?: Partial<ICitaParams>) => {
  try {
    if (!checkValidLogin()) return;

    const url = `/citas-medicas/referencias?timestamp=${new Date().getTime()}&${search}`;
    const data = { ...params };
    const isCentro = claveCentroMedico === 'UMFC';
    const headers = {
      'Authorization': localStorage.getItem('token') || sessionStorage.getItem('token')
    }
    const resp = isCentro ? await AXIOS_SIPEM_CENTRO.post(url, data, { headers }) : await AXIOS_SIPEM.post(url, data, { headers });

    return resp.data as ICitaMedica[];
  } catch (error) {
    handleAxiosError(error);
  }
}

export const getCitaRezagadas = async (idPersonal: number, idBeneficiario: number | null, claveCentroMedico: string) => {
  try {
    if (!checkValidLogin()) return;

    const url = `/citas-medicas/rezagadas?timestamp=${new Date().getTime()}`;
    const data = { idPersonal, idBeneficiario };
    const isCentro = claveCentroMedico === 'UMFC';
    const headers = {
      'Authorization': localStorage.getItem('token') || sessionStorage.getItem('token')
    }
    const resp = isCentro ? await AXIOS_SIPEM_CENTRO.post(url, data, { headers }) : await AXIOS_SIPEM.post(url, data, { headers });

    return resp.data as ICitaMedica[];
  } catch (error) {
    handleAxiosError(error);
  }
}

export const getDiasFestivos = async (anio: number, cuenta: number, idCentroMedico: number) => {
  try {
    if (!checkValidLogin()) return;

    const url = `/paquetes/dias-no-laborales`;
    const data = { anio, cuenta, idCentroMedico };
    const resp = await AXIOS_SIPEM.post(url, data, {
      headers: {
        'Authorization': localStorage.getItem('token') || sessionStorage.getItem('token')
      }
    });
    return resp.data as IDiaNoLaborable[];
  } catch (error) {
    handleAxiosError(error);
  }
}

export const getAgendaEnLinea = async (idCentroMedico: number, claveCentroMedico: string, enLinea: number) => {
  try {
    if (!checkValidLogin()) return;

    const query = enLinea ? `enLinea=${enLinea}` : '';
    const url = `/agenda-en-linea/${idCentroMedico}?${query}`;
    const isCentro = claveCentroMedico === 'UMFC';
    const headers = {
      'Access-Centro-Medico': claveCentroMedico,
      'Authorization': localStorage.getItem('token') || sessionStorage.getItem('token')
    }
    const resp = isCentro ? await AXIOS_SIPEM_CENTRO.get(url, { headers }) : await AXIOS_SIPEM.get(url, { headers });
    return resp.data as IAgendaEnLinea[];
  } catch (error) {
    handleAxiosError(error);
  }
}

export const getCasosEspecialesLocal = async () => {
  try {
    if (!checkValidLogin()) return;

    const url = `/casos-especiales/local`;
    const resp = await AXIOS_SIPEM.get(url, {
      headers: {
        'Authorization': localStorage.getItem('token') || sessionStorage.getItem('token')
      }
    });
    return resp.data as ICasoEspecialLocal[];
  } catch (error) {
    handleAxiosError(error);
  }
}

export const getParametrosOnlineByCentroMedico = async (idCentroMedico: number) => {
  try {
    if (!checkValidLogin()) return;

    const url = `/centros-medicos/${idCentroMedico}/parametros-online`;
    const resp = await AXIOS_SIPEM.get(url, {
      headers: {
        'Authorization': localStorage.getItem('token') || sessionStorage.getItem('token')
      }
    });
    return resp.data as IParametroOnline[];
  } catch (error) {
    handleAxiosError(error);
  }
}

export const getParamsRefByCentroMedico = async (idCentroMedico: number) => {
  try {
    if (!checkValidLogin()) return;

    const url = `/params-referencia/centro-medico/${idCentroMedico}`;
    const resp = await AXIOS_SIPEM.get(url, {
      headers: {
        'Authorization': localStorage.getItem('token') || sessionStorage.getItem('token')
      }
    });
    return resp.data as IParametroOnline[];
  } catch (error) {
    handleAxiosError(error);
  }
}

export const getTestRespiratorio = async (search: string) => {
  try {
    if (!checkValidLogin()) return;

    const url = `/test-respiratorio?${search}`;
    const resp = await AXIOS_SIPEM.get(url, {
      headers: {
        'Authorization': localStorage.getItem('token') || sessionStorage.getItem('token')
      }
    });
    return resp.data as ITestRespiratorio[];
  } catch (error) {
    handleAxiosError(error);
  }
}

export const getPortalParametrosGlobales = async (search: string) => {
  try {
    if (!checkValidLogin()) return;

    const url = `/portal-parametros-globales?${search}`;
    const resp = await AXIOS_SIPEM.get(url, {
      headers: {
        'Authorization': localStorage.getItem('token') || sessionStorage.getItem('token')
      }
    });
    return resp.data as IPortalParametroGlobal[];
  } catch (error) {
    handleAxiosError(error);
  }
}

export const getEstatusLaboralByTipoTrabajador = async (search: string) => {
  try {
    if (!checkValidLogin()) return;

    const url = `/estatus-laboral/tipo-trabajador?${search}`;
    const resp = await AXIOS_SIPEM.get(url, {
      headers: {
        'Authorization': localStorage.getItem('token') || sessionStorage.getItem('token')
      }
    });
    return resp.data as ITipoTrabajadorAtencion[];
  } catch (error) {
    handleAxiosError(error);
  }
}

export const getConfiguracionAgenda = async (search: string, claveCentroMedico: string, notNull?: string[]) => {
  try {
    if (!checkValidLogin()) return;

    const url = `/configuracion-agenda?${search}`;
    const isCentro = claveCentroMedico === 'UMFC';
    const params = { notNull };
    const headers = {
      'Access-Centro-Medico': claveCentroMedico,
      'Authorization': localStorage.getItem('token') || sessionStorage.getItem('token')
    }
    const resp = isCentro ? await AXIOS_SIPEM_CENTRO.get(url, { params, headers }) : await AXIOS_SIPEM.get(url, { params, headers });
    return resp.data as IConfiguracionAgenda[];
  } catch (error) {
    handleAxiosError(error);
  }
}

// POST

export const saveCita = async (claveCentroMedico: string, fichaCita?: Partial<IFichaCita>, referenciaEspecialidad?: Partial<IReferenciaEspecialidad>, params?: Partial<ICitaParams>) => {
  try {
    if (!checkValidLogin()) return;

    const url = `/citas-medicas/save`;
    const data = { fichaCita, params: params || null, referenciaEspecialidad };
    const isCentro = claveCentroMedico === 'UMFC';
    const headers = {
      'Access-Centro-Medico': claveCentroMedico,
      'Authorization': localStorage.getItem('token') || sessionStorage.getItem('token')
    }
    const resp = isCentro ? await AXIOS_SIPEM_CENTRO.post(url, data, { headers }) : await AXIOS_SIPEM.post(url, data, { headers });
    return resp.data as { message: string, saved: boolean };
  } catch (error) {
    handleAxiosError(error);
  }
}

export const saveTestRespiratorio = async (testRespiratorio: ITestRespiratorio[]) => {
  try {
    if (!checkValidLogin()) return;

    const url = `/test-respiratorio`;
    const data = { testRespiratorio };
    const resp = await AXIOS_SIPEM.post(url, data, {
      headers: {
        'Authorization': localStorage.getItem('token') || sessionStorage.getItem('token')
      }
    });
    return resp.data as boolean;
  } catch (error) {
    handleAxiosError(error);
  }
}

export const getMedicoActivo = async (idEspecialidad: number, idArea: number, idTurno: number, idConsultorio: number, idCentroMedico: number, claveCentroMedico?: string) => {
  try {
    const url = `/paquetes/medico-activo`;
    const isCentro = claveCentroMedico === 'UMFC';
    const data = { idEspecialidad, idArea, idTurno, idConsultorio, idCentroMedico }
    const headers = {
      'Access-Centro-Medico': claveCentroMedico,
      'Authorization': localStorage.getItem('token') || sessionStorage.getItem('token')
    }
    const resp = isCentro ? await AXIOS_SIPEM_CENTRO.post(url, data, { headers }) : await AXIOS_SIPEM.post(url, data, { headers });
    return resp.data as IMedicoActivo[];
  } catch (error) {
    handleAxiosError(error)
  }
}

// DELETE

export const deleteCitaMedica = async (idCita: number, claveCentroMedico: string) => {
  try {
    if (!checkValidLogin()) return;

    const url = `/citas-medicas/${idCita}`;
    const isCentro = claveCentroMedico === 'UMFC';
    const data = { enlinea: true };
    const headers = {
      'Access-Centro-Medico': claveCentroMedico,
      'Authorization': localStorage.getItem('token') || sessionStorage.getItem('token')
    }
    const resp = isCentro ? await AXIOS_SIPEM_CENTRO.delete(url, { data, headers }) : await AXIOS_SIPEM.delete(url, { data, headers });
    return resp.data as boolean;
  } catch (error) {
    handleAxiosError(error);
  }
}

