import React from 'react';
import useNow from '../hooks/useNow';
import { Card, CardHeader, Grid, Typography, useTheme } from '@material-ui/core';
import { decodeCentroMedico, decodeToken, titleCase } from '../utils/functions';

const Header = () => {
  const theme = useTheme();
  const derechohabiente = decodeToken();
  const centroMedico = decodeCentroMedico();
  const [nowDate, nowTime] = useNow();

  return (
    <header>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={9}>
          <Card elevation={0}>
            <CardHeader
              style={{ padding: 0 }}
              disableTypography
              title={
                <>
                  <Typography variant='caption' color='textSecondary'>
                    Hoy es <span style={{ color: theme.palette.primary.main }}>{nowDate}</span>. Son las <span style={{ color: theme.palette.primary.main }}>{nowTime}</span>.
                  </Typography>
                  <Typography variant="h4" color='textSecondary' component='h2'>
                    Bienvenido(a) {titleCase(`${derechohabiente.NOMBRE} ${derechohabiente.AP_PATERNO} ${derechohabiente.AP_MATERNO || ''}`)}
                  </Typography>
                </>
              }
              subheader={
                <>
                  <Typography variant='subtitle2' color='textSecondary'>
                    Número de cuenta: {derechohabiente.NUMERO_CUENTA}{derechohabiente.PARENTESCO ? '/' + derechohabiente.PARENTESCO.CLAVE : '/A'}
                  </Typography>
                  <Typography variant='subtitle2' color='textSecondary'>
                    RFC: {derechohabiente.RFC}
                  </Typography>
                  <Typography variant='subtitle2' color='textSecondary'>
                    Unidad médica: {centroMedico ? titleCase(centroMedico.DESCRIPCION) : 'S/N'}
                  </Typography>
                </>
              }
            />
          </Card>
        </Grid>
      </Grid>
    </header>
  );
};

export default Header;