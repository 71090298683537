import React/*, { useState } */ from 'react';
import { Redirect } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Layout from '../components/Layout';
import ModuleCard from '../components/ModuleCard';
// import SearchModule from '../components/SearchModule';
import { checkValidLogin } from '../utils/functions';
import { modules } from '../utils/modules';

const Home = () => {
  // const [searchWord, setSearchWord] = useState('');

  if (!checkValidLogin()) return <Redirect to='/login' />

  return (
    <Layout title='Inicio'>
      {/* <SearchModule searchWord={searchWord} setSearchWord={setSearchWord} /> */}

      <Grid container spacing={3} style={{ marginTop: 15 }}>
        {modules/* .filter(module => module.name.toUpperCase().includes(searchWord.toUpperCase())) */.map((module, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <ModuleCard module={module} />
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
};

export default Home;