import { AXIOS_SIPEM } from "../../utils/constants";
import { handleAxiosError } from "../../utils/functions";
import { ILicenciaMedica } from "./interfaces";

export const getLicenciaMedica = async (folio: number, cuenta: number) => {
  try {
    const url = `/incapacidades/${folio}/licencia-medica/${cuenta}`;
    const resp = await AXIOS_SIPEM.get(url, {
      headers: {
        'Authorization': sessionStorage.getItem('token')
      }
    });
    return resp.data as ILicenciaMedica;
  } catch (error) {
    handleAxiosError(error);
  }
}