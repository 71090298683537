import React from 'react';
import { Grid, useTheme } from '@material-ui/core';
import { Redirect } from 'react-router';
import Layout from '../../components/Layout';
import ModuleCard from '../../components/ModuleCard';
import { checkValidLogin, decodeCentroMedico, titleCase } from '../../utils/functions';
import { IModule } from '../../utils/interfaces';

const CitasMedicas = () => {

  const theme = useTheme();
  const centroMedico = decodeCentroMedico();

  if (!checkValidLogin()) return <Redirect to='/' />

  const modules: IModule[] = [
    {
      name: 'Ver citas',
      description: 'Ver todas las citas que se encuentran agendadas.',
      to: '/citas-medicas/ver-citas',
      icon: null,
      show: true
    },
    {
      name: 'Consulta general',
      description: 'Agendar una nueva cita para consulta general.',
      to: '/citas-medicas/consulta-general',
      icon: null,
      show: true,
      disabled: !centroMedico || centroMedico?.DIRECCION_IP === 'No' || centroMedico?.CITA_EN_LINEA === 0 || false,
      msgDisabled: `El servicio en línea no está disponible ${centroMedico ? `para la ${titleCase(centroMedico.DESCRIPCION)}` : ''}.`
    },
    {
      name: 'Referencias',
      description: 'Agendar una nueva cita para medicina de especialidad con referencia.',
      to: '/citas-medicas/referencias',
      icon: null,
      show: true
    }
  ];

  return (
    <Layout title='Citas médicas'>
      <Grid container spacing={4} style={{ marginTop: theme.spacing(3) }}>
        {modules.filter(module => module.show).map((module, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index} style={{ opacity: module.disabled ? 0.5 : 1 }}>
            <ModuleCard module={module} />
          </Grid>
        ))}
      </Grid>
    </Layout >
  );
};

export default CitasMedicas;