import { AXIOS_SIPEM } from './constants';
import { ICentroMedico } from './interfaces';
import { checkValidJwt, handleAxiosError } from './functions';
import { toast } from 'react-toastify';

export const login = async (loginData: { NOMBRE_COMPLETO: string, NUMERO_CUENTA: number, RFC: string }) => {
  try {
    const url = `/auth/login`;
    const data = {
      NOMBRE_COMPLETO: loginData.NOMBRE_COMPLETO,
      NUMERO_CUENTA: loginData.NUMERO_CUENTA,
      RFC: loginData.RFC
    };
    const resp = await AXIOS_SIPEM.post(url, data);
    const res = resp.data as { token: string, mensajes: { msg: string }[] };

    res.mensajes.map(mensaje => toast.error(<div dangerouslySetInnerHTML={{ __html: mensaje.msg.replace(/\\n/g, " <br /> ") }} />, (res.mensajes.length == 1)? {autoClose: 10000} : {autoClose: 15000}));

    return res.token;
  } catch (error) {
    handleAxiosError(error);
  }
}

export const logout = async () => {
  try {
    const url = `/auth/logout`;
    const resp = await AXIOS_SIPEM.delete(url, {
      headers: {
        'Authorization': sessionStorage.getItem('token') || localStorage.getItem('token')
      }
    });
    const res = resp.data as boolean;

    return res;
  } catch (error) {
    handleAxiosError(error);
  }
}

export const getCentroMedico = async (search: string, notNull?: string[]) => {
  try {
    if (!checkValidJwt()) return;

    const url = `/centros-medicos?${search}`;
    const resp = await AXIOS_SIPEM.get(url, {
      params: { notNull },
      headers: {
        'Authorization': localStorage.getItem('token') || sessionStorage.getItem('token')
      }
    });
    return resp.data as ICentroMedico[];
  } catch (error) {
    handleAxiosError(error);
  }
}