import React from 'react';
import 'moment/locale/es';
import moment from 'moment';
import { Typography, useTheme } from '@material-ui/core';

moment.locale('es');

const Footer = () => {
  const theme = useTheme();

  return (
    <div style={{ padding: theme.spacing(2), width: '100%', textAlign: 'center' }}>
      <Typography variant='caption' color='textSecondary'>
        <a href="http://www.isset.gob.mx/" target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none', color: theme.palette.primary.main }}>Instituto de Seguridad Social del Estado de Tabasco</a>
          &copy; - {moment().year()}
      </Typography>
    </div>
  );
};

export default Footer;