import axios from "axios";
import { getApiUrl } from "./functions";

export const APP_NAME = 'Prestaciones médicas';

export const ESCALA_GRIS: boolean = false;

export const API_CONFIG = {
  sipem: process.env.REACT_APP_SIPEM_API,
  sipemCentro: process.env.REACT_APP_SIPEM_CENTRO_API
}

export const AXIOS_SIPEM = axios.create({
  baseURL: getApiUrl('sipem'),
  validateStatus: (status) => status >= 200 && status <= 305,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': typeof window !== 'undefined' ? window.sessionStorage.getItem('token') || window.localStorage.getItem('token') : ''
  }
});

export const AXIOS_SIPEM_CENTRO = axios.create({
  baseURL: getApiUrl('sipem', true),
  validateStatus: (status) => status >= 200 && status <= 305,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': typeof window !== 'undefined' ? window.sessionStorage.getItem('token') || window.localStorage.getItem('token') : ''
  }
});