import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router';
import { makeStyles, Theme, createStyles, Card, CardHeader, Typography, IconButton, useTheme } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { IModule } from '../utils/interfaces';
import { ESCALA_GRIS } from '../utils/constants';

const useStyles = makeStyles((theme: Theme) => createStyles({
  card: {
    cursor: 'pointer',
    height: '100%',
    boxShadow: theme.overrides.MuiPaper.elevation1['boxShadow'],
    '&:hover': {
      boxShadow: theme.overrides.MuiPaper.elevation4['boxShadow']
    }
  }
}));

interface IModuleCardProps {
  module: IModule
}

const ModuleCard: FunctionComponent<IModuleCardProps> = ({ module }) => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();

  return (
    <Card className={classes.card} onClick={e => !module.disabled ? history.push(module.to) : null}>
      <CardHeader
        disableTypography
        title={<Typography variant="h5">{module.name}</Typography>}
        subheader={
          <>
            {!module?.disabled ? <Typography variant="body2" color="textSecondary" style={{ marginTop: 15 }}>{module.description}</Typography> : null}
            {module?.disabled && module.msgDisabled ? <Typography variant='body2' color='error' style={{ marginTop: theme.spacing(2) }}>{module?.msgDisabled}</Typography> : null}
          </>
        }
        action={
          <IconButton color='primary' disabled style={{ color: ESCALA_GRIS ? '' : 'rgba(157,36,73,0.55)' }}>
            <ArrowForward />
          </IconButton>
        }
      />
    </Card>
  );
};

export default ModuleCard;