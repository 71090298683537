import React, { FunctionComponent } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { Avatar, Button, Card, CardContent, CardHeader, Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import FichaCita from '../FichaCita';
import { decodeToken, titleCase } from '../../../utils/functions';
import { ICitaMedica } from '../../../pages/CitasMedicas/interfaces';
import { Flag } from '@material-ui/icons';
import { grey, yellow } from '@material-ui/core/colors';

moment.locale('es');

interface ICardCitaProps {
  cita: ICitaMedica,
  optionalStep?: () => any,
  finalStep?: () => any,
}

const CardCita: FunctionComponent<ICardCitaProps> = ({ cita, optionalStep, finalStep }) => {
  const theme = useTheme();
  const derechohabiente = decodeToken();

  return (
    <Card elevation={1}>
      <CardHeader
        avatar={
          <Avatar style={{ backgroundColor: theme.palette.primary.main }}>
            {cita.FICHA}
          </Avatar>
        }
        action={
          <IconButton disabled>
            <Flag style={{
              color: cita.REFERENCIA ? yellow[800] : cita.ENLINEA === 1 ? '#9D2449' : grey[500]
            }} />
          </IconButton>
        }
        title={
          titleCase(`${derechohabiente.NOMBRE} ${derechohabiente.AP_PATERNO} ${derechohabiente.AP_MATERNO}`)
        }
        subheader={
          <Typography variant='subtitle2' color='textSecondary' style={{ backgroundColor: yellow[500] }}>
            {moment(cita.FECHA_CITA).utc().format('DD [de] MMMM [de] YYYY')} a la(s) {moment(cita.HORA, 'H:mm').format('H:mm a')}
          </Typography>
        }
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant='body2'>
              <b>Número de cuenta:</b> {derechohabiente.NUMERO_CUENTA}{derechohabiente.PARENTESCO ? '/' + derechohabiente.PARENTESCO.CLAVE : '/A'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2'>
              <b>Fecha de la cita:</b> {moment(cita.FECHA_CITA).utc().format('DD/MM/YYYY')}
            </Typography>
          </Grid>
          {cita.REFERENCIA ? (
            <Grid item xs={12}>
              <Typography variant='body2'>
                <b>Folio de la referencia:</b> {cita.REFERENCIA.FOLIO_CONSECUTIVO}
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Typography variant='body2'>
              <b>Hora de la cita:</b> {moment(cita.HORA, 'H:mm').format('H:mm a')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2'>
              <b>Consultorio:</b> {cita.CONSULTORIO.CONSULTORIO}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2'>
              <b>Centro médico:</b> {titleCase(cita.CENTRO_MEDICO.DESCRIPCION)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2'>
              <b>Médico que atiende:</b> {titleCase(`${cita.MEDICO_CITA.NOMBRE} ${cita.MEDICO_CITA.AP_PATERNO} ${cita.MEDICO_CITA.AP_MATERNO || ''}`)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2'>
              <b>Especialidad:</b> {titleCase(cita.ESPECIALIDAD.DESCRIPCION)}
            </Typography>
          </Grid>
          {cita.PROGRAMA_ATENCION ? (
            <Grid item xs={12}>
              <Typography variant='body2'>
                <b>Programa de atención:</b> {titleCase(cita.PROGRAMA_ATENCION.DESCRIPCION)}
              </Typography>
            </Grid>
          ) : null}
        </Grid>

        <Grid item xs={12}>
          <FichaCita
            datosCita={cita}
            resultadoTest={0}
            isReferencia={!!cita.REFERENCIA}
            optionalAction={
              optionalStep ? (
                <Button
                  color='primary'
                  id={cita?.ID_CITA.toString() || ''}
                  variant='outlined'
                  onClick={optionalStep}
                >Reagendar</Button>
              ) : null
            }
            finalButton={
              finalStep /* && cita.ENLINEA === 1 && !cita.REFERENCIA */ /* && cita.ID_TIPO_ATENCION !== 2 */ ? (
                <Button
                  color='primary'
                  id={cita?.ID_CITA.toString() || ''}
                  variant='text'
                  onClick={finalStep}
                  disabled={
                    !moment().isBefore(moment(moment(cita.FECHA_CITA).toString().replace('00:00:00', `${cita.HORA}:00`)))
                  }
                >Cancelar cita</Button>
              ) : null
            }
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CardCita;