import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import { IPortalParametroGlobal } from '../interfaces';
import { getPortalParametrosGlobales } from '../services';

const CuestionarioSatisfaccion = () => {
  const [open, setOpen] = useState(true);
  const [parametrosGlobales, setParametrosGlobales] = useState<IPortalParametroGlobal[]>([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const respParams = await getPortalParametrosGlobales('');
        setParametrosGlobales(respParams);
      } catch (error) {
        console.error(error);
      }
    }

    getData();
  }, []);

  if (parametrosGlobales.find(param => param.NOMBRE_PARAMETRO === 'Mostrar encuesta')?.ACTIVO === 0 || parametrosGlobales.find(param => param.NOMBRE_PARAMETRO === 'Mostrar encuesta')?.VALOR !== 'true') return null;

  return (
    <Dialog open={open} onClose={e => setOpen(false)}>
      <DialogTitle>
        <Typography variant='h4' component='span'>Encuesta de satisfacción</Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>{parametrosGlobales.find(param => param.NOMBRE_PARAMETRO === 'Leyenda encuesta')?.VALOR || '¿Qué te ha parecido el proceso para tramitar tu cita médica?'}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={e => setOpen(false)}>No, gracias</Button>

        <Button variant='contained' color='primary' onClick={e => {
          window.open(parametrosGlobales.find(param => param.NOMBRE_PARAMETRO === 'Link encuesta')?.VALOR, '_blank');
          setOpen(false);
        }}>Comenzar encuesta</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CuestionarioSatisfaccion;