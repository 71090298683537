import React, { FunctionComponent, useState } from 'react';
import { useWindowSize } from 'react-use';
import { Link as RouterLink } from 'react-router-dom'
import { AppBar, Avatar, Button, createStyles, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, MenuItem, Theme, Toolbar, Typography, Menu } from '@material-ui/core';
import { ExitToApp, Help, Home, Language, Menu as Burger, MoreVert, Phone } from '@material-ui/icons';
import { APP_NAME } from '../utils/constants';
import { useHistory } from 'react-router';
import { decodeToken, titleCase } from '../utils/functions';
import { modules } from '../utils/modules';
import { logout } from '../utils/services';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      flexGrow: 1,
    },
    avatarContent: {
      minWidth: 250,
      maxWidth: 300,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      textAlign: 'center'
    },
    avatar: {
      width: 70,
      height: 70,
      margin: '0 auto',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 5px 20px rgba(157, 36, 73, 0.2)'
    },
    fullname: {
      wordBreak: 'break-word',
      marginTop: theme.spacing(2)
    },
    desktopGroup: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block'
      }
    },
    mobileGroup: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    }
  }),
);

interface INavbarProps {
  title?: string,
  hideBurger?: boolean
}

const Navbar: FunctionComponent<INavbarProps> = ({ title, hideBurger }) => {
  const classes = useStyles();
  const history = useHistory();
  const size = useWindowSize();
  const derechohabiente = decodeToken();
  const [open, setOpen] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const renderMobileMenu = (
    <Menu
      keepMounted
      elevation={1}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={() => setMobileMoreAnchorEl(null)}
    >
      <MenuItem dense component='a' target='_blank' rel='noopener noreferrer' href='http://www.isset.gob.mx/'>
        <ListItemIcon>
          <Language fontSize='small' />
        </ListItemIcon>
        <ListItemText primary='Portal ISSET' />
      </MenuItem>
      <MenuItem dense component='a' rel='noopener noreferrer' href='tel:8000909090'>
        <ListItemIcon>
          <Phone fontSize='small' />
        </ListItemIcon>
        <ListItemText primary='ISSETel' secondary='8000 909090' />
      </MenuItem>
    </Menu>
  )

  const exitSession = async () => {
    try {
      await logout();

      sessionStorage.clear();
      localStorage.clear();
      history.push('/login');
    } catch (error) {
      console.error(error);
    }
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  return (
    <>
      <AppBar position='sticky' color='primary' component='div'>
        <Toolbar>
          {!hideBurger ? <IconButton color='inherit' aria-label='menu' onClick={e => setOpen(true)}>
            <Burger />
          </IconButton> : null}
          <Typography className={classes.title}>{APP_NAME} {title ? `| ${title}` : ""}</Typography>
          <div className={classes.desktopGroup}>
            <Button
              style={{ textTransform: 'initial' }}
              component='a'
              color='inherit'
              target='_blank'
              rel='noopener noreferrer'
              href='http://www.isset.gob.mx/'
              startIcon={<Language />}
            >
              Portal ISSET
            </Button>
            <Button
              style={{ textTransform: 'initial' }}
              component='a'
              color='inherit'
              rel='noopener noreferrer'
              href='tel:8000909090'
              startIcon={<Phone />}
            >
              ISSETel (<Typography variant='caption'>8000 909090</Typography>)
            </Button>
          </div>
          <div className={classes.mobileGroup}>
            <IconButton color='inherit' onClick={handleMobileMenuOpen}>
              <MoreVert />
            </IconButton>

            {size.width < 960 && isMobileMenuOpen ? renderMobileMenu : null}
          </div>
        </Toolbar>
      </AppBar>

      {!hideBurger ? <Drawer open={open} onClose={() => setOpen(false)}>
        <div className={classes.avatarContent}>
          <Avatar className={classes.avatar}>
            {derechohabiente.NOMBRE.charAt(0).toUpperCase()}
            {derechohabiente.NOMBRE.charAt(1).toUpperCase()}
          </Avatar>
          <Typography variant='subtitle2' color='textSecondary' className={classes.fullname}>
            {titleCase(`${derechohabiente.NOMBRE} ${derechohabiente.AP_PATERNO} ${derechohabiente.AP_MATERNO || ''}`)}
          </Typography>
        </div>
        <Divider />

        <List component='nav' style={{ marginTop: 15 }}>
          <ListItem button component={RouterLink} to='/'>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary='Inicio' />
          </ListItem>
          {modules.map((module, index) => (
            <ListItem key={index} button component={RouterLink} to={module.to}>
              <ListItemIcon>{module.icon}</ListItemIcon>
              <ListItemText primary={module.name} />
            </ListItem>
          ))}
          <ListItem button onClick={e => window.open('http://presmed.isset.gob.mx:9005/static/Guia-de-uso-20230210.pdf', '_blank')}>
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            <ListItemText primary='Guía de uso' />
          </ListItem>
          <ListItem button onClick={exitSession}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText primary='Cerrar sesión' />
          </ListItem>
        </List>
      </Drawer> : null}
    </>
  );
};

export default Navbar;