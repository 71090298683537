import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Login from './pages/Login';
import LicenciaMedica from './pages/LicenciaMedica';
import Constancias from './pages/ConstanciasCuidadosMenor';
import CitasMedicas from './pages/CitasMedicas';
import ConsultaCita from './pages/CitasMedicas/ConsultaCita';
import ConsultaGeneral from './pages/CitasMedicas/ConsultaGeneral';
import Referencias from './pages/CitasMedicas/Referencias';

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={Home}></Route>
        <Route exact path='/login' component={Login}></Route>
        <Route exact path='/licencia-medica' component={LicenciaMedica}></Route>
        <Route exact path='/constancias-cuidados-menor' component={Constancias}></Route>
        <Route exact path='/citas-medicas' component={CitasMedicas}></Route>
        <Route exact path='/citas-medicas/ver-citas' component={ConsultaCita}></Route>
        <Route exact path='/citas-medicas/consulta-general' component={ConsultaGeneral}></Route>
        <Route exact path='/citas-medicas/referencias' component={Referencias}></Route>
        <Route component={NotFound}></Route>
      </Switch>
    </Router>
  );
};

export default Routes;