import { useEffect, useState } from "react";
import moment from 'moment';
import 'moment/locale/es';

const useNow = (): [string, string] => {
  const [dateTime, setDateTime] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => setDateTime(moment()), 1000);

    const cleanUp = () => {
      clearInterval(interval);
    }

    return cleanUp;
  }, []);

  return [
    `${moment(dateTime).locale("es").format("DD")} de ${moment(dateTime).format("MMMM")} de ${moment(dateTime).year()}`,
    `${moment(dateTime).format("hh:mm a")}`
  ];
}

export default useNow;