import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { yellow } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  span: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main
  },
  citasContainer: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  addCita: {
    border: '1px solid red'
  },
  values: {
    width: '100%',
    display: 'grid',
    textAlign: 'center',
    gridTemplateColumns: '1fr repeat(2, 50px)',
    '& > *:last-child, & > *:nth-child(2)': {
      marginRight: 24
    }
  },
  form: {
    marginTop: theme.spacing(3)
  },
  licencia: {
    marginTop: theme.spacing(3)
  },
  cardSubheader: {
    backgroundColor: yellow[600]
  },
  closeButtonDialog: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 5,
    color: theme.palette.grey[500]
  },
  cardButton: {
    cursor: 'pointer'
  },
  line: {
    width: 60,
    height: 5,
    content: '',
    backgroundColor: theme.palette.primary.main
  }
}));