import React, { useState } from 'react';
import { Button, Card, CardContent, CircularProgress, Container, Grid, TextField, Typography, useTheme } from "@material-ui/core";
import moment from 'moment';
import { titleCase, toCapitalize } from '../../utils/functions';
import { useStyles } from './styles';
import { ILicenciaMedica } from './interfaces';
import { getLicenciaMedica } from './functions';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';

const LicenciaMedica = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [licencia, setLicencia] = useState<ILicenciaMedica>();
  const [data, setData] = useState<{ folio: number, cuenta: number }>({
    folio: 0,
    cuenta: 0
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setLoading(true);
      setLicencia(null);

      if (!data.folio || !data.cuenta) return setError(true);

      const resp = await getLicenciaMedica(data.folio, data.cuenta);

      if (!resp) return toast.error('No se encontró licencia médica con los datos ingresados.');
      if (resp.ESTATUS_VALIDACION === 0) return toast.error('El folio ingresado no fue autorizado.');
      if (!resp.ESTATUS_VALIDACION) return toast.error('El folio ingresado no es valido, pues no ha finalizado con el proceso de autorización.');

      setLicencia(resp);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Helmet title='Prestaciones médicas | Licencia médica' />

      <Navbar title='Licencia médica' hideBurger />

      <Container style={{ marginTop: theme.spacing(6), marginBottom: theme.spacing(6) }}>
        <div style={{ marginBottom: theme.spacing(4) }}>
          <a href="http://www.isset.gob.mx/" target='_blank' rel='nopener noreferrer'>
            <img style={{ maxWidth: 130 }} src={require('../../assets/images/logo-isset-negro.png').default} alt='Logo ISSET' />
          </a>
        </div>

        <div style={{ marginBottom: theme.spacing(4) }}>
          <Typography variant='h1' style={{ fontSize: 'calc(32px + 8 * (100vw - 0px) / 1650)', fontWeight: 'bold' }}>Verificar licencia médica</Typography>
          <div className={classes.line}></div>
        </div>

        <Typography>Para verificar la autenticidad de una licencia médica y tener la certeza de que fueron elaboradas por un médico del instituto, así como registradas en el expediente clínico del derechohabiente, se requiere el folio de la licencia médica y el número de cuenta del derechohabiente:</Typography>

        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name='folio'
                label='Folio*'
                margin='dense'
                color='primary'
                variant='outlined'
                error={error}
                value={data.folio || 0}
                helperText={error && !data.folio ? 'Campo obligatorio' : ''}
                onChange={(e) => setData({ ...data, folio: parseInt(e.target.value) })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name='cuenta'
                margin='dense'
                label='Cuenta*'
                color='primary'
                variant='outlined'
                error={error}
                value={data.cuenta || 0}
                helperText={error && !data.cuenta ? 'Campo obligatorio' : ''}
                onChange={(e) => setData({ ...data, cuenta: parseInt(e.target.value) })}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type='submit'
                color='primary'
                variant='contained'
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} color='inherit' /> : null}
              >
                {loading ? 'Verificando' : 'Verificar'}
              </Button>
            </Grid>
          </Grid>
        </form>

        {licencia ? (
          <Grid container spacing={3} className={classes.licencia}>
            <Grid item xs={12}>
              <Typography variant='h3' style={{ fontSize: 'calc(20px + 8 * (100vw - 0px) / 1650)', fontWeight: 'bold' }}>Datos de la licencia médica</Typography>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent style={{ paddingBottom: 16 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Typography><b>Identificador de la licencia:</b> {licencia.ID_INCAPACIDAD}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography><b>Folio:</b> {licencia.FOLIO}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography><b>Días de licencia:</b> {licencia.DIAS_LICENCIA} días</Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography><b>Consecutivo:</b> {titleCase(licencia.SECUENCIA_NO_LICENCIA)}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Typography><b>Fecha de inicio:</b> {moment(licencia.FECHA_INICIO).format('DD [de] MMMM [de] YYYY')}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Typography><b>Fecha de termino:</b> {moment(licencia.FECHA_TERMINO).format('DD [de] MMMM [de] YYYY')}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Typography><b>Fecha de captura:</b> {moment(licencia.FECHA_CAPTURA).format('DD [de] MMMM [de] YYYY')}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Typography><b>Médico:</b> {titleCase(licencia.MEDICO)}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Typography><b>Cédula:</b> {licencia.CEDULA_MEDICO}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Typography><b>Especialidad:</b> {toCapitalize(licencia.ESPECIALIDAD)}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Typography><b>Dependencia u orgnismo:</b> {toCapitalize(licencia.DEPENDENCIA)}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Typography><b>Diagnóstico:</b> {toCapitalize(licencia.ENFERMEDAD)}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Typography><b>Unidad médica:</b> {titleCase(licencia.CENTRO_MEDICO)}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : null}
      </Container>

      <div style={{ position: 'absolute', bottom: 0, width: '100%', textAlign: 'center' }}>
        <Footer />
      </div>
    </>
  )
};

export default LicenciaMedica;