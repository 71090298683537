import { AxiosError } from "axios";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { toast } from "react-toastify";
import { API_CONFIG } from "./constants";
import { IErrorApi, ITokenPersona } from './interfaces';

export const handleAxiosError = (error: any) => {
  if (!error || !error.response) return toast.error('Ha ocurrido un error inesperado. Intente más tarde.');

  const status = error.response.status;
  const data = error.response?.data;
  const errors: IErrorApi[] = data?.errors;

  if (status === 404 && data && !errors && !errors.length) return toast.error(data);
  if (status === 404 && errors && errors?.length > 0) return errors.map(error => toast.error(error.msg));

  if (status === 503 || status === 504) return toast.error('El servicio no se encuentra disponible. Por favor, intente más tarde.');
  if (status >= 500) return toast.error('El servidor no está disponible. Intente más tarde.');

  if (status === 401) {
    if (typeof data != 'string' && !data?.token) {
      sessionStorage.clear();
      localStorage.clear();
      return;
    }

    if (checkValidLogin()) return;

    return toast.error(data);
  }

  if (status >= 400) return toast.error('La petición que solicitó no pudo ser concluida. Recargue e intente de nuevo.');
};

export const getApiUrl = (api: 'sipem', isCentro: boolean = false) => {
  if (process.env.REACT_APP_ENV === 'production' && process.env.REACT_APP_SIPEM_CENTRO_API) {
    switch (api) {
      case 'sipem':
        if (isCentro) return API_CONFIG.sipemCentro;
        return API_CONFIG.sipem;
      default: return '';
    }
  }

  switch (api) {
    case 'sipem': return API_CONFIG.sipem;
    default: return '';
  }
}

export const getAge = (date: string) => {
  const today = new Date();
  const birthDate = new Date(date);
  const m = today.getMonth() - birthDate.getMonth();
  let age = today.getFullYear() - birthDate.getFullYear();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age--;
  return age;
}

export const encodeQueryData = (data: any) => {
  const ret = [];
  for (let d in data) {
    if (data[d] !== '0' && data[d] !== '' && data[d] !== null && data[d] !== undefined) {
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }
  }
  return ret.join('&');
}

export const toCapitalize = (str: string) => str.charAt(0).toUpperCase() + str.substr(1, str.length).toLowerCase();

export const titleCase = (str: string) => {
  const words = str.toLowerCase().split(' ');
  for (let i = 0; i < words.length; i++) words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  return words.join(' ');
}

export const randomNumber = (max: number, min: number = 0) => Math.floor(Math.random() * (max - min + 1)) + min;

export const removeItemOnce = (array: any[], value: any) => {
  const index = array.indexOf(value);
  if (index > -1) array.splice(index, 1);
}

export const checkValidJwt = (_token?: string) => {
  const token = _token || localStorage.getItem('token') || sessionStorage.getItem('token');

  if (!token) {
    sessionStorage.clear();
    localStorage.clear();
    return false;
  }

  const decoded: ITokenPersona = jwtDecode(token);
  if (decoded.exp < (Date.now() / 1000)) {
    sessionStorage.clear();
    localStorage.clear();
    return false;
  }

  return true;
}

export const checkValidLogin = (_token?: string) => {
  const token = _token || localStorage.getItem('token') || sessionStorage.getItem('token');
  const centroMedico = localStorage.getItem('centroMedico') || sessionStorage.getItem('centroMedico');

  if (!token || !centroMedico) {
    sessionStorage.clear();
    localStorage.clear();
    return false;
  }

  const decoded: ITokenPersona = jwtDecode(token);
  if (decoded.exp < (Date.now() / 1000)) {
    sessionStorage.clear();
    localStorage.clear();
    return false;
  }

  return true;
}

export const flatten = (array: any[]) => {
  var flattend: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  !(function flat(array) {
    array.forEach(function (el) {
      if (Array.isArray(el)) flat(el);
      else flattend.push(el);
    });
    return true;
  })(array);
  return flattend;
}

export const decodeToken = (_token?: string) => {
  const token = _token || localStorage.getItem('token') || sessionStorage.getItem('token') || '';
  if (!token) return;
  const decoded: ITokenPersona = jwtDecode(token);
  return decoded;
}

export const decodeCentroMedico = () => {
  const centroMedico = localStorage.getItem('centroMedico') || sessionStorage.getItem('centroMedico');

  if (!centroMedico || centroMedico.trim() === 'undefined') return;

  return JSON.parse(centroMedico);
}

export const enumerateDaysBetweenDates = function (startDate: Date, endDate: Date, excludes: Date[] = [], params: Partial<{
  excludeFirst: boolean,
  excludeLast: boolean
}> = {}): Date[] {
  const dates: Date[] = [];
  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  const isExcludeFirst = excludes.some(exclude => moment(exclude).isSame(currDate, 'date'));
  const isExcludeLast = excludes.some(exclude => moment(exclude).isSame(lastDate, 'date'));

  if (!params?.excludeFirst && !isExcludeFirst) dates.push(currDate.toDate());

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    if (!excludes.some(exclude => moment(exclude).isSame(currDate, 'date'))) {
      if (currDate.day() !== 6 && currDate.day() !== 0) {
        dates.push(currDate.clone().toDate());
      }
    }
  }

  if (!params?.excludeLast && currDate.isSame(lastDate, 'date') && !isExcludeLast) dates.push(lastDate.toDate());

  return dates;
};