import React from 'react';
import { Grid } from '@material-ui/core';
import { Redirect } from 'react-router';
import Layout from '../../../components/Layout';
import AgendarCitaReferencia from './AgendarCitaReferencia';
import { checkValidLogin } from '../../../utils/functions';

const Referencias = () => {

  if (!checkValidLogin()) return <Redirect to='/' />

  return (
    <Layout title='Referencias'>
      <Grid container style={{ marginTop: 60 }}>
        <Grid item xs={12}>
          <AgendarCitaReferencia />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Referencias;