import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Print } from '@material-ui/icons';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/es';
import { useReactToPrint } from 'react-to-print';
import PrintCita from './ConsultaGeneral/PrintCita';
import { ICitaMedica, ISintomaOnline, ITestRespiratorio } from './interfaces';
import { getTestRespiratorio, saveTestRespiratorio } from './services';
import { encodeQueryData, decodeToken, decodeCentroMedico } from '../../utils/functions';

moment.locale('es');

interface IFichaCitaProps {
  resultadoTest: number,
  datosCita: ICitaMedica,
  sintomas?: ISintomaOnline[],
  optionalAction?: any,
  finalButton?: any,
  showPreview?: boolean,
  isReferencia?: boolean
}

const FichaCita: FunctionComponent<IFichaCitaProps> = ({ datosCita, resultadoTest, showPreview, isReferencia, sintomas, finalButton, optionalAction }) => {

  const citaRef = useRef();
  const derechohabiente = decodeToken();
  const centroMedico = decodeCentroMedico();
  const [loading, setLoading] = useState(false);
  const [testRespiratorio, setTestRespiratorio] = useState<ITestRespiratorio[]>([]);

  const reactToPrintContent = React.useCallback(() => citaRef.current, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `ficha_cita_${moment().format('DDMMYYYY[_]Hmm')}`,
    pageStyle: 'padding: 100px;'
  });

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        let test: ITestRespiratorio[] = [];

        if ((!sintomas || sintomas.length === 0) && !isReferencia) {
          test = await getTestRespiratorio(encodeQueryData({ ID_CITA: datosCita.ID_CITA }));
        } else if (!isReferencia && sintomas && sintomas.length) {
          sintomas.map(sintoma => {
            test.push({
              ID_SINTOMA: sintoma.ID_SINTOMA,
              DESCRIPCION: sintoma.DESCRIPCION,
              VALOR: sintoma.TOGGLE === 1 ? 1 : 0,
              FECHA_REGISTRO: moment().format('DD/MM/YY H:mm:ss'),
              ID_PERSONAL: derechohabiente.ID_PERSONAL,
              ID_PACIENTE_BENEFICIARIO: derechohabiente?.ID_BENEFICIARIO || null,
              ID_CITA: datosCita?.ID_CITA || null
            });
            return null;
          });

          if (resultadoTest < 5) await saveTestRespiratorio(test);
        }

        setTestRespiratorio(test);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    getData();
  }, [datosCita?.ID_CITA, derechohabiente?.ID_BENEFICIARIO, derechohabiente.ID_PERSONAL, isReferencia, resultadoTest, sintomas]);

  if (loading) return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress size={40} color='primary' />
    </div>
  )

  return (
    <div>
      <div style={{ display: showPreview ? 'block' : 'none' }}>
        <PrintCita
          ref={citaRef}
          isReferencia={isReferencia}
          derechohabiente={derechohabiente}
          centroMedico={centroMedico}
          datosCita={datosCita || null}
          resultadoTest={resultadoTest}
          testRespiratorio={testRespiratorio}
        />
      </div>
      <Grid container spacing={2} alignItems='center' style={{ marginTop: 15 }}>
        <Grid item>
          <Button
            variant='outlined'
            color='primary'
            onClick={handlePrint}
            startIcon={<Print />}
          >Imprimir</Button>
        </Grid>
        {optionalAction ? optionalAction : null}
        {finalButton ? finalButton : null}
      </Grid>
    </div>
  );
}

export default FichaCita;