import React, { useState } from 'react';
import { Button, Card, CardContent, CircularProgress, Container, Grid, TextField, Typography, useTheme } from "@material-ui/core";
import moment from 'moment';
import { titleCase, toCapitalize } from '../../utils/functions';
import { useStyles } from './styles';
import { IConstancia } from './interfaces';
import { getConstancias } from './functions';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';

const Constancias = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [constancia, setConstancia] = useState<IConstancia>();
  const [data, setData] = useState<{ folio: number, cuenta: number }>({
    folio: 0,
    cuenta: 0
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setLoading(true);
      setConstancia(null);

      if (!data.folio || !data.cuenta) return setError(true);

      const resp = await getConstancias(data.folio, data.cuenta);

      if (!resp || resp.CANCELADA === 1) return toast.error('No existe constancia con esos datos o está cancelada.');
      if (resp.ESTATUS_VALIDACION !== 1 || (resp.REQUIERE_AUTORIZACION === 1 && resp.ID_MEDICO_AUTORIZA === null && (resp.ESTATUS_VALIDACION === null || resp.ESTATUS_VALIDACION === 1)))return toast.error('La constancia no ha finalizado el proceso de validación.');
   

      setConstancia(resp);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Helmet title='Prestaciones médicas | Constancias de cuidado del menor' />

      <Navbar title='Constancias de cuidado del menor' hideBurger />

      <Container style={{ marginTop: theme.spacing(6), marginBottom: theme.spacing(6) }}>
        <div style={{ marginBottom: theme.spacing(4) }}>
          <a href="http://www.isset.gob.mx/" target='_blank' rel='nopener noreferrer'>
            <img style={{ maxWidth: 130 }} src={require('../../assets/images/logo-isset-negro.png').default} alt='Logo ISSET' />
          </a>
        </div>

        <div style={{ marginBottom: theme.spacing(4) }}>
          <Typography variant='h1' style={{ fontSize: 'calc(32px + 8 * (100vw - 0px) / 1650)', fontWeight: 'bold' }}>Verificar constancias de cuidados del menor</Typography>
          <div className={classes.line}></div>
        </div>

        <Typography>Para verificar la autenticidad de una constancias de cuidados del menor y tener la certeza de que fueron elaboradas por un médico del instituto, así como registradas en el expediente clínico del derechohabiente, se requiere el folio de la constancia y el número de cuenta del derechohabiente:</Typography>

        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name='folio'
                label='Folio*'
                margin='dense'
                color='primary'
                variant='outlined'
                error={error}
                value={data.folio || 0}
                helperText={error && !data.folio ? 'Campo obligatorio' : ''}
                onChange={(e) => setData({ ...data, folio: parseInt(e.target.value) })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name='cuenta'
                margin='dense'
                label='Cuenta*'
                color='primary'
                variant='outlined'
                error={error}
                value={data.cuenta || 0}
                helperText={error && !data.cuenta ? 'Campo obligatorio' : ''}
                onChange={(e) => setData({ ...data, cuenta: parseInt(e.target.value) })}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type='submit'
                color='primary'
                variant='contained'
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} color='inherit' /> : null}
              >
                {loading ? 'Verificando' : 'Verificar'}
              </Button>
            </Grid>
          </Grid>
        </form>

        {constancia ? (
          <Grid container spacing={3} className={classes.licencia}>
            <Grid item xs={12}>
              <Typography variant='h3' style={{ fontSize: 'calc(20px + 8 * (100vw - 0px) / 1650)', fontWeight: 'bold' }}>Datos de la constancia de cuidado del menor</Typography>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent style={{ paddingBottom: 16 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Typography><b>Identificador de la nota médica:</b> {constancia.ID_EVOLUCION}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography><b>Folio de la constancia:</b> {constancia.ID_CUIDADO_MATERNO}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography><b>Días de cuidado:</b> {constancia.DIAS} días</Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Typography><b>Fecha de inicio:</b> {moment(constancia.FECHA_INICIO).format('DD [de] MMMM [de] YYYY')}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Typography><b>Fecha de termino:</b> {moment(constancia.FECHA_TERMINO).format('DD [de] MMMM [de] YYYY')}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Typography><b>Fecha de captura:</b> {moment(constancia.FECHA_CAPTURA).format('DD [de] MMMM [de] YYYY')}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Typography><b>Médico:</b> {titleCase(constancia.MEDICO)}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Typography><b>Cédula:</b> {constancia.CEDULA_PROFESIONAL}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Typography><b>Especialidad:</b> {toCapitalize(constancia.ESPECIALIDAD)}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Typography><b>Dependencia u orgnismo:</b> {toCapitalize(constancia.DEPENDENCIAS)}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Typography><b>Diagnóstico del menor:</b> {toCapitalize(constancia.DIAGNOSTICO)}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Typography><b>Unidad médica:</b> {titleCase(constancia.CENTRO_MEDICO)}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : null}
      </Container>

      <div style={{ position: 'absolute', bottom: 0, width: '100%', textAlign: 'center' }}>
        <Footer />
      </div>
    </>
  )
};

export default Constancias;