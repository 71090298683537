import React from 'react';
import { useHistory } from 'react-router';
import { useWindowSize } from 'react-use';
import { Grid, Paper, Breadcrumbs, Typography, useTheme, Button } from '@material-ui/core';
import { Home, ArrowBack } from '@material-ui/icons'
import { Link } from 'react-router-dom';
import { grey } from '@material-ui/core/colors';
import { logout } from '../utils/services';

const CustomBreadcrumb = () => {
  const history = useHistory();
  const theme = useTheme();
  const size = useWindowSize();
  const pathnames = window ? window.location.pathname.split('/').filter(x => x) : [];
  const isHomePage = pathnames.length === 0;

  const breadcrumbNameMap: { [key: string]: string } = {
    '/citas-medicas': 'Citas médicas',
    '/citas-medicas/consulta-general': 'Consulta general',
    '/citas-medicas/referencias': 'Referencias',
    '/citas-medicas/ver-citas': 'Ver citas'
  }

  const exitSession = async () => {
    try {
      await logout();

      sessionStorage.clear();
      localStorage.clear();
      history.push('/login');
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Paper elevation={1} style={{ marginBottom: theme.spacing(6) }}>
      <Grid container alignItems="center" style={{ padding: 10 }}>
        <Grid item style={{ marginRight: 10 }}>
          {isHomePage ? <Home fontSize="small" style={{ cursor: 'pointer', marginTop: 4 }} /> : null}
          {!isHomePage ? <ArrowBack fontSize="small" style={{ cursor: 'pointer', marginTop: 4 }} onClick={() => history.goBack()} /> : null}
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <Breadcrumbs maxItems={size.width < 960 ? 2 : 5} separator='/' aria-label="breadcrumb">
            {pathnames.length <= 0 ? (
              <Typography color='primary'>Inicio</Typography>
            ) : (
              <Link to="/" style={{
                color: `${isHomePage ? theme.palette.primary.main : grey[400]}`,
                textDecoration: `${isHomePage ? 'none' : 'underline'}`,
              }}>Inicio</Link>
            )}

            {pathnames.map((value, index) => {
              const last = index === pathnames.length - 1;
              const to = `/${pathnames.slice(0, index + 1).join('/')}`;

              return last ? (
                <Typography key={to} style={{ color: theme.palette.primary.main }} >
                  <span>{breadcrumbNameMap[to]}</span>
                </Typography>
              ) : (
                <Link to={to} key={to} style={{ color: grey[400], textDecoration: 'underline' }}>
                  {breadcrumbNameMap[to]}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            size='small'
            color='primary'
            variant='outlined'
            onClick={exitSession}
          >
            Cerrar sesión
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CustomBreadcrumb;