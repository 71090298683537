import React, { Component } from 'react';
import moment from 'moment';
import { Check, Clear } from '@material-ui/icons';
import { Grid, Typography } from '@material-ui/core';
import { ICentroMedico, ITokenPersona } from '../../../utils/interfaces';
import { ICitaMedica, ITestRespiratorio } from '../interfaces';
import { titleCase, toCapitalize } from '../../../utils/functions';

import classes from '../../../assets/styles/cita.module.scss';

export default class ComponentToPrint extends Component<{
  derechohabiente: ITokenPersona,
  centroMedico: ICentroMedico,
  resultadoTest: number,
  testRespiratorio: ITestRespiratorio[],
  datosCita?: ICitaMedica,
  isReferencia?: boolean
}, {}> {
  render() {
    const { derechohabiente, centroMedico, resultadoTest, datosCita, testRespiratorio, isReferencia } = this.props;

    if (!derechohabiente || !centroMedico) return null;

    return (
      <Grid style={{ marginTop: 10 }} container spacing={2} alignItems='flex-start'>
        <img className={classes.image} src={require('../../../assets/images/logo-isset-negro.png').default} alt='Logo ISSET' />
        <Grid item xs={12}>
          <Typography variant='h2'>Datos de la {resultadoTest < 5 ? 'cita' : 'solicitud'}</Typography>
        </Grid>

        <Grid item xs={12} container spacing={1}>
          {resultadoTest < 5 || centroMedico.CLAVE !== 'UMFC' ? (
            <>
              <Grid item xs={12}>
                <Typography variant='body2'><b>Origen de la cita:</b> {datosCita.ENLINEA === 1 ? 'Portal' : 'SIPEM'} </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body2'><b>Número de cita:</b> {datosCita?.ID_CITA} (<b>Fecha de expedición:</b> {moment(datosCita.FECHA_CAPTURA).format('DD/MM/YYYY HH:mm')})</Typography>
              </Grid>
              {isReferencia && datosCita.REFERENCIA ? <Grid item xs={12}>
                <Typography variant='body2'><b>Folio de referencia:</b> {datosCita.REFERENCIA.FOLIO_CONSECUTIVO}</Typography>
              </Grid> : null}
            </>
          ) : null}

          <Grid item xs={12}>
            <Typography variant='body2'><b>Derechohabiente:</b> {titleCase(`${derechohabiente.NOMBRE} ${derechohabiente.AP_PATERNO} ${derechohabiente.AP_MATERNO || ''}`)}</Typography>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item>
              <Typography variant='body2'><b>Número de cuenta:</b> {derechohabiente.NUMERO_CUENTA}{derechohabiente.PARENTESCO ? '/' + derechohabiente.PARENTESCO.CLAVE : '/A'}</Typography>
            </Grid>
            {(resultadoTest < 5) ?(
                <>
                  {datosCita.TELEFONO ? (
                    <Grid item>
                      <Typography variant='body2'><b>Contacto:</b> {datosCita.TELEFONO} {datosCita.DETALLE_TELEFONO ? ' - ' + datosCita.DETALLE_TELEFONO : ''}</Typography>
                    </Grid>
                  ) : null}
                </> 
            ) : null}
          </Grid>

          <Grid item xs={12}>
            <Typography variant='body2'><b>Centro médico:</b> {titleCase(datosCita?.CENTRO_MEDICO ? datosCita.CENTRO_MEDICO.DESCRIPCION : centroMedico.DESCRIPCION)}</Typography>
          </Grid>

          {resultadoTest < 5 || centroMedico.CLAVE !== 'UMFC' ? (
            <>
              <Grid item xs={12}>
                <Typography variant='body2'><b>Domicilio:</b> {titleCase(datosCita?.CENTRO_MEDICO ? datosCita.CENTRO_MEDICO.DOMICILIO : centroMedico.DOMICILIO)}</Typography>
              </Grid>
              <Grid item xs={12} container spacing={2}>
                <Grid item>
                  <Typography variant='body2'><b>Consultorio médico:</b> {datosCita.CONSULTORIO.CONSULTORIO}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body2'><b>Turno:</b> {toCapitalize(datosCita.TURNO.DESCRIPCION)}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body2'><b>Médico que atiende:</b> {titleCase(`${datosCita.MEDICO_CITA.NOMBRE} ${datosCita.MEDICO_CITA.AP_PATERNO} ${datosCita.MEDICO_CITA.AP_MATERNO || ''}`)}</Typography>
              </Grid>
              <Grid item xs={12} container spacing={2}>
                <Grid item>
                  <Typography variant='body2'><b>Especialidad:</b> {titleCase(datosCita.ESPECIALIDAD.DESCRIPCION)}</Typography>
                </Grid>
                {datosCita.PROGRAMA_ATENCION ? <Grid item>
                  <Typography variant='body2'><b>Programa de atención:</b>{titleCase(datosCita.PROGRAMA_ATENCION.DESCRIPCION)}</Typography>
                </Grid> : null}
              </Grid>
              <Grid item xs={12} container spacing={2}>
                <Grid item>
                  <Typography variant='body2'><b>Fecha de la cita:</b> {moment(datosCita.FECHA_CITA).utc().format('DD/MM/YYYY')}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body2'><b>Hora de la cita:</b> {moment(datosCita.HORA, 'H:mm').format('H:mm a')}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body2'><b>Ficha de la cita:</b> {datosCita.FICHA}</Typography>
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>

        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12}>
            <Typography variant={resultadoTest < 5 || centroMedico.CLAVE !== 'UMFC' ? 'h4' : 'h3'} component='h3' color='primary'>¡Aviso!</Typography>
          </Grid>

          {resultadoTest >= 5 && centroMedico.CLAVE === 'UMFC' ? (
            <>
              <Grid item xs={12}>
                <Typography variant='body2'>Con base en los resultados del test respiratorio, el paciente debe acudir al <b>módulo de enfermedades respiratorias</b> a fin de que se valore si los síntomas presentados son por COVID-19. En caso de ser positivo, recibirá atención y orientación médica en dicho módulo. En caso de ser negativo, se le otorgará consulta de medicina familiar.</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body2'>El módulo se encuentra ubicado en la <b>{titleCase(centroMedico.DESCRIPCION)}</b> en {titleCase(centroMedico.DOMICILIO)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body2'>Para más información o dudas puedes comunicarte a la línea <b>ISSETel</b> al <b>8000 909090</b>.</Typography>
              </Grid>
            </>
          ) : null}

          {resultadoTest < 5 || centroMedico.CLAVE !== 'UMFC' ? (
            <Grid item xs={12}>
              <ul>
                <li>
                  <Typography variant='body2'>Toma nota de los datos de tu cita</Typography>
                </li>
                <li>
                  <Typography variant='body2'>Paciente de primera vez y subsecuente, presentarse en el módulo de confirmación 30 minutos antes de la hora seleccionada para confirmar su cita.</Typography>
                </li>
                <li dangerouslySetInnerHTML={{
                  __html: `En caso de no poder asistir a tu cita médica, la debes cancelar en la línea <b>ISSETel al 8000 909090</b>${!isReferencia ? '; o bien en la opción <b>Cancelar cita</b> desde <b>Opciones de citas</b>, en este portal' : ''}.`
                }}>
                  {/* <Typography variant='body2'>.</Typography> */}
                </li>
                <li>
                  <Typography variant='body2'>En caso de que la cita sea para un menor de edad, éste deberá ser acompañado por un adulto.</Typography>
                </li>
              </ul>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Typography variant='body2'>Por tu cooperación muchas gracias.</Typography>
          </Grid>
        </Grid>

        {(resultadoTest < 5 || centroMedico.CLAVE !== 'UMFC') && !isReferencia ? (
          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Typography variant='h5' component='h3'>Protocolo de ingreso y egreso de los pacientes:</Typography>
            </Grid>
            <Grid item xs={12}>
              <ul>
                <li>
                  <Typography variant='body2'>El paciente hará una fila respetando la sana distancia de 1.50 mts, guiándose por las marcas en el piso, para ser atendido por el personal de filtro.</Typography>
                </li>
                <li>
                  <Typography variant='body2'>El paciente, podrá ingresar 15 min. antes, posterior al interrogatorio de síntomas respiratorios. Si el paciente no es sospechoso, podrá ingresar a la clínica después de tomarle la temperatura, aplicarle alcohol en gel al 70% y solución clorada en las suelas de los zapatos.</Typography>
                </li>
                <li>
                  <Typography variant='body2'>Se le indicará subir por la rampa para accesar a los consultorios de planta alta, a menos que sea paciente de VIH, paciente discapacitado o de la tercera edad, donde se le indicará que lo atenderán en planta baja.</Typography>
                </li>
                <li>
                  <Typography variant='body2'>Después de su consulta, el paciente bajará por las escaleras para dirigirse a la farmacia y posteriormente salir por el filtro.</Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
        ) : null}

        {testRespiratorio && testRespiratorio.length > 0 ? (
          <Grid item xs={12} container>
            <Grid item xs={12} style={{ marginBottom: 15 }}>
              <Typography variant='h5' component='h3'>Test respiratorio</Typography>
            </Grid>

            {testRespiratorio.map((test, index) => (
              <Grid key={index} item xs={12} container spacing={1}>
                <Grid item xs={10} sm={8}>
                  <Typography variant='body2'>{test.DESCRIPCION}</Typography>
                </Grid>
                <Grid item xs>
                  {test.VALOR === 1 ? <Check color='primary' /> : <Clear color='disabled' />}
                </Grid>
              </Grid>
            ))}
          </Grid>
        ) : null}

        <Typography variant='body2' className={classes.date}>{moment().format('DD [de] MMMM [del] YYYY')}</Typography>
      </Grid>
    );
  }
}