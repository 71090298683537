import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import Layout from '../../../components/Layout';
import { checkValidLogin } from '../../../utils/functions';
import { Paper, Tab, Tabs, useTheme } from '@material-ui/core';
import Agendadas from './Agendadas';
import Rezagadas from './Rezagadas';
import { IPortalParametroGlobal } from '../interfaces';
import { getPortalParametrosGlobales } from '../services';

const ConsultaCita = () => {
  const theme = useTheme();
  const [tab, setTab] = useState(0);
  const [parametrosGlobales, setParametrosGlobales] = useState<IPortalParametroGlobal[]>([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await getPortalParametrosGlobales('');
        setParametrosGlobales(resp);
      } catch (error) {
        console.error(error);
      }
    }

    getData();
  }, []);

  if (!checkValidLogin()) return <Redirect to='/login' />

  return (
    <Layout title='Ver citas'>
      <Paper elevation={1} style={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }}>
        <Tabs value={tab} indicatorColor='primary' onChange={(e, value) => setTab(value)}>
          <Tab label='Agendadas' />
          <Tab label='Rezagadas' />
        </Tabs>
      </Paper>

      {tab === 0 ? <Agendadas parametrosGlobales={parametrosGlobales} /> : null}
      {tab === 1 ? <Rezagadas parametrosGlobales={parametrosGlobales} setTab={setTab} /> : null}
    </Layout>
  );
};

export default ConsultaCita;