import moment from "moment";
import { getAge } from "../../utils/functions";
import { ITokenPersona } from "../../utils/interfaces";
import { IProgramaAtencion, ICasoEspecialLocal } from '../../pages/CitasMedicas/interfaces';

export const filterProgramasAtencion = (_derechohabiente: ITokenPersona, _programas: IProgramaAtencion[], _casosEspeciales: ICasoEspecialLocal[] = []) => {
  if (_programas.length <= 0) return [];

  let programas = _programas;
  const edad = getAge(_derechohabiente.FECHA_NACIMIENTO);

  // omitir casos especiales de los programas de atencion
  if (_casosEspeciales.length > 0) {
    _casosEspeciales.map(caso =>
      programas = programas.filter(programa => programa.ID_PROGRAMA_ATENCION !== caso.ID_CASO)
    );
  }

  // que el programa este activo
  programas = programas.filter(programa => programa.ACTIVO === 1);
  // sea del mismo sexo o para ambos
  programas = programas.filter(programa => programa.SEXO === _derechohabiente.SEXO || !programa.SEXO);
  // cumpla con la edad minima
  programas = programas.filter(programa => edad >= programa.EDAD_MINIMA || !programa.EDAD_MINIMA);
  // cumpla con el maximo de edad permitido
  programas = programas.filter(programa => edad <= programa.EDAD || !programa.EDAD);


  return programas;
}

export const getNextDay = (days: number, excludes: Date[], current: Date): Date => {
  const fecha = moment(current);
  const currIsExclude = excludes.some(dia => moment(dia).isSame(fecha, 'date'));

  if (currIsExclude || fecha.day() === 6 || fecha.day() === 0) return getNextDay(days, excludes, fecha.add(1, 'day').toDate());
  if (days <= 0) return fecha.toDate();
  return getNextDay(days - 1, excludes, fecha.add(1, 'day').toDate());
}