import React from 'react';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import Layout from '../../../components/Layout';
import AgendarCita from './AgendarCita';
import { checkValidLogin } from '../../../utils/functions';
import { Redirect } from 'react-router';

const useStyles = makeStyles((theme: Theme) => createStyles({
  parrafo: {
    '& span, & a': {
      fontWeight: 'bold',
      color: theme.palette.primary.main
    }
  }
}));

const ConsultaGeneral = () => {
  const classes = useStyles();

  if (!checkValidLogin()) return <Redirect to='/login' />

  return (
    <Layout title='Consulta general'>
      <Grid container spacing={6}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} className={classes.parrafo}>
            <Typography>Aquí podrás gestionar y solicitar tus citas para consulta general en tu Unidad de Medicina Familiar correspondiente. Preséntate a tu cita 30 minutos antes, con tu credencial del ISSET o una identificación oficial.</Typography>
          </Grid>
          <Grid item xs={12} className={classes.parrafo}>
            <Typography>Si sospechas que tus síntomas son indicativos de COVID, <span>NO ES NECESARIO</span> que solicites una cita. Preséntate directamente en las Unidades de Atención a Enfermedades Respiratorias (<a href="http://www.isset.gob.mx/servicios-medicos/domicilios-umfs" target='_blank' rel='noopener noreferrer'>consulta aquí el domicilio</a>), o en tu Unidad de Medicina Familiar municipal, para recibir atención y valoración.</Typography>
          </Grid>
          <Grid item xs={12} className={classes.parrafo}>
            <Typography>Puedes comunicarte a la línea de <span>ISSETel al 8000 909090</span> para dudas, comentarios o aclaraciones.</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <AgendarCita />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ConsultaGeneral;