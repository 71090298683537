import { createMuiTheme } from '@material-ui/core/styles';
import { ESCALA_GRIS } from './constants';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: ESCALA_GRIS ? '#767576' : '#9d2449',
      contrastText: '#fff'
    },
    secondary: {
      main: '#333333',
      contrastText: '#fff'
    },
    text: {
      primary: '#333333'
    },
    background: {
      default: '#fff'
    }
  },
  typography: {
    h1: {
      fontSize: 'calc(45px + 8 * (100vw - 0px) / 1650)'
    },
    h2: {
      fontSize: 'calc(32px + 8 * (100vw - 0px) / 1650)'
    },
    h3: {
      fontSize: 'calc(28px + 8 * (100vw - 0px) / 1650)'
    },
    h4: {
      fontSize: 23
    },
    h5: {
      fontSize: 19
    },
    h6: {
      fontSize: 18
    }
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        boxShadow: ESCALA_GRIS ? '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' : '0px 3px 1px -2px rgb(157 36 73 / 20%), 0px 2px 2px 0px rgb(157 36 73 / 14%), 0px 1px 5px 0px rgb(157 36 73 / 12%)',
        '&:hover': {
          boxShadow: ESCALA_GRIS ? '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' : '0px 2px 4px -1px rgb(157 36 73 / 20%), 0px 4px 5px 0px rgb(157 36 73 / 14%), 0px 1px 10px 0px rgb(157 36 73 / 12%)'
        }
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0px 7px 15px rgba(199, 199, 199, 0.45)'
      },
      elevation2: {
        boxShadow: '0px 10px 15px rgba(199, 199, 199, 0.50)'
      },
      elevation3: {
        boxShadow: '3px 10px 15px rgba(199, 199, 199, 0.55)'
      },
      elevation4: {
        boxShadow: '3px 13px 15px rgba(199, 199, 199, 0.60)'
      }
    }
  }
});

export default theme;