import React, { Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { Button, Card, CardHeader, CircularProgress, Grid, TextField, Typography, useTheme } from '@material-ui/core';
import { getReferenciasVigentes } from '../services';
import { IReferenciaVigente } from './interfaces';
import { decodeToken, titleCase } from '../../../utils/functions';
import { toast } from 'react-toastify';

moment.locale('es');

interface IBuscarReferenciaProps {
  referencias: IReferenciaVigente[],
  referencia: IReferenciaVigente,
  setReferencias: Dispatch<SetStateAction<IReferenciaVigente[]>>,
  setReferencia: Dispatch<SetStateAction<IReferenciaVigente>>,
  nextStep: () => any
}

const BuscarReferencia: FunctionComponent<IBuscarReferenciaProps> = ({ referencias, referencia, setReferencias, setReferencia, nextStep }) => {
  const theme = useTheme();
  const derechohabiente = decodeToken();
  const [folio, setFolio] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getData = useCallback(
    async () => {
      try {
        if (referencias.length > 0) return;

        setLoading(true);
        setError(false);
        setReferencias([]);

        const resp = await getReferenciasVigentes(derechohabiente?.ID_PERSONAL, derechohabiente?.ID_BENEFICIARIO);

        if (!resp || !resp.length) return;

        setReferencias(resp);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }, [derechohabiente?.ID_BENEFICIARIO, derechohabiente?.ID_PERSONAL, referencias.length, setReferencias]
  )

  const handleCard = (e: React.MouseEvent<HTMLDivElement>) => {
    try {
      const referenciaItem = referencias.find(referencia => referencia.FOLIO_CONSECUTIVO === parseInt(e.currentTarget.id));
      setReferencia(referenciaItem);
    } catch (error) {
      console.error(error);
    }
  }

  const validateFolio = async (e: React.FormEvent) => {
    try {
      e.preventDefault();

      if (folio !== referencia.FOLIO_CONSECUTIVO) return toast.error('El folio ingresado no coincide con la referencia seleccionada.');

      nextStep();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getData();
  }, [getData])

  if (loading) return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress size={50} color='primary' />
    </div>
  )

  return (
    <Grid container spacing={3} style={{ marginTop: 15 }}>
      {!referencias.length ? (
        <Grid item xs={12} container spacing={3} justify='center' style={{ maxWidth: 800, margin: '0 auto' }}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography color='error'>No se encontraron referencias vigentes activas</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2'>Si tiene la certeza de que cuenta con una referencia, es posible que:</Typography>
            <ul>
              <li>Los datos aún no se han actualizado en el centro médico al que fue referido(a).</li>
              <li>La referencia que busca no requiera cita.</li>
              <li>El centro médico al que fue referido(a) no se encuentre habilitado para agendar cita en línea</li>
            </ul>
            <Typography variant='body2'>Para mayor información, favor de comunicarse a ISSETel (8000 909090)</Typography>
          </Grid>
        </Grid>
      ) : null}

      {referencias.length > 0 ? (
        <Grid item xs={12} container spacing={2}>
          {referencias.map((referenciaItem, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <Card
                style={{
                  cursor: 'pointer',
                  border: referencia && referenciaItem.FOLIO_CONSECUTIVO === referencia.FOLIO_CONSECUTIVO ? `1px solid ${theme.palette.primary.main}` : '1px solid rgba(0, 0, 0, 0.12)'
                }}
                variant='outlined'
                onClick={handleCard}
                id={referenciaItem.FOLIO_CONSECUTIVO.toString()}
              >
                <CardHeader
                  title={
                    <Typography variant='body1'>{titleCase(referenciaItem.CENTRO_MEDICO_DESTINO)}</Typography>
                  }
                  subheader={
                    <>
                      <Typography variant='body2' style={{ display: 'inline' }}>{titleCase(referenciaItem.ESPECIALIDAD)}</Typography>
                      <Typography variant='body2' component='span'>{` — ${moment(referenciaItem.FECHA_REFERENCIA).format('DD [de] MMMM [de] YYYY')}`}</Typography>
                    </>
                  }
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : null}

      {referencia ? (
        <React.Fragment>
          <Grid item xs={12}>
            <Typography>Ingrese el folio de su referencia para validar datos.</Typography>
          </Grid>

          <Grid item xs={12} container spacing={2} alignItems='flex-start' component='form' onSubmit={validateFolio}>
            <Grid item xs={8} sm={5} md={3}>
              <TextField
                fullWidth
                size='small'
                label='Folio*'
                error={error && !folio}
                variant='outlined'
                value={folio || 0}
                helperText={error && !folio ? 'Campo obligatorio' : null}
                onChange={e => setFolio(parseInt(e.target.value))}
              />
            </Grid>
            <Grid item>
              <Button
                type='submit'
                color='primary'
                variant='outlined'
                disabled={loading}
                startIcon={loading ? <CircularProgress color='inherit' size={20} /> : null}
              >
                {!loading ? 'Validar' : 'Validando folio'}
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      ) : null}
    </Grid>
  );
};

export default BuscarReferencia;