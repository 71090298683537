import React, { Fragment, FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Container, useTheme } from '@material-ui/core'
import { APP_NAME } from '../utils/constants';
import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';
import CustomBreadcrumb from "./CustomBreadcrumb";

interface ILayoutProps {
  title?: string
}

const Layout: FunctionComponent<ILayoutProps> = ({ title, children }) => {
  const theme = useTheme();

  return (
    <Fragment>
      <div style={{ minHeight: '92vh' }}>
        <Helmet>
          <title>{APP_NAME} {title ? `| ${title}` : ""}</title>
        </Helmet>

        <Navbar title={title} />

        <Container style={{ marginTop: theme.spacing(6), marginBottom: theme.spacing(6) }}>

          <CustomBreadcrumb />

          <Header />

          <div style={{ marginTop: theme.spacing(3) }}>
            {children}
          </div>

        </Container>
      </div>

      <Footer />
    </Fragment>
  );
};

export default Layout;