import React, { useState } from 'react';
import { Button, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import BuscarReferencia from './BuscarReferencia';
import { IReferenciaVigente } from './interfaces';
import { useWindowSize } from 'react-use';
import DisponibilidadReferencia from './DisponibilidadReferencia';
import { ICitaMedica } from '../interfaces';
import FichaCita from '../FichaCita';
import { removeItemOnce } from '../../../utils/functions';
import { useHistory } from 'react-router';

const AgendarCitaReferencia = () => {
  const size = useWindowSize();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [cita, setCita] = useState<ICitaMedica>();
  const [referencia, setReferencia] = useState<IReferenciaVigente>();
  const [referencias, setReferencias] = useState<IReferenciaVigente[]>([]);

  const nextStep = () => setActiveStep(activeStep + 1);
  const prevStep = () => setActiveStep(activeStep - 1);
  const finalStep = () => {
    try {
      removeItemOnce(referencias, referencia);
      setReferencia(null);
      setCita(null);

      if (!referencias.length) {
        history.push('/citas-medicas');
      } else {
        setActiveStep(0);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <Stepper alternativeLabel={size.width < 700} activeStep={activeStep} style={{ padding: 0, marginBottom: 24 }}>
        <Step style={{ textAlign: size.width < 700 ? 'center' : 'left' }}>
          <StepLabel optional={<Typography variant='caption'>Seleccione la referencia</Typography>}>Referencias activas</StepLabel>
        </Step>
        <Step style={{ textAlign: size.width < 700 ? 'center' : 'left' }}>
          <StepLabel optional={<Typography variant='caption'>Ya casi. ¡Un paso más!</Typography>}>Consultar disponibilidad</StepLabel>
        </Step>
        <Step style={{ textAlign: size.width < 700 ? 'center' : 'left' }} completed={activeStep === 2}>
          <StepLabel optional={<Typography variant='caption'>Datos de la cita médica</Typography>}>Verifica que todo sea correcto</StepLabel>
        </Step>
      </Stepper>

      {activeStep === 0 ? (
        <BuscarReferencia
          referencias={referencias}
          referencia={referencia}
          setReferencias={setReferencias}
          setReferencia={setReferencia}
          nextStep={nextStep}
        />
      ) : null}
      {activeStep === 1 ? (
        <DisponibilidadReferencia
          referencia={referencia}
          setCita={setCita}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      ) : null}
      {activeStep === 2 ? (
        <FichaCita
          isReferencia
          showPreview
          sintomas={[]}
          datosCita={cita}
          resultadoTest={0}
          finalButton={
            <Button
              color='primary'
              variant='contained'
              onClick={finalStep}
            >Finalizar</Button>
          }
        />
      ) : null}
    </>
  );
};

export default AgendarCitaReferencia;