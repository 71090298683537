import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import moment from 'moment';
import { Card, CardContent, CardHeader, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { decodeCentroMedico, decodeToken, encodeQueryData, removeItemOnce, titleCase } from '../../../utils/functions';
import { ICitaMedica, IConfiguracionAgenda, IPortalParametroGlobal } from '../interfaces';
import { getCitaRezagadas, getConfiguracionAgenda, getMedicoActivo } from '../services';
import CardCita from './CardCita';
import { IReferenciaVigente } from '../Referencias/interfaces';
import DisponibilidadReferencia from '../Referencias/DisponibilidadReferencia';
import { Help } from '@material-ui/icons';

interface IRezagadasProps {
  parametrosGlobales: IPortalParametroGlobal[],
  setTab?: Dispatch<SetStateAction<number>>,
}

const Rezagadas: FunctionComponent<IRezagadasProps> = ({ parametrosGlobales, setTab }) => {
  const derechohabiente = decodeToken();
  const centroMedico = decodeCentroMedico();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCita, setLoadingCita] = useState(false);
  const [agendaActiva, setAgendaActiva] = useState(true);
  const [citaGuardada, setCitaGuardada] = useState<ICitaMedica>();
  const [citas, setCitas] = useState<ICitaMedica[]>([]);
  const [referencia, setReferencia] = useState<IReferenciaVigente>();
  const [citaSeleccionada, setCitaSeleccionada] = useState<ICitaMedica>();

  const getData = async () => {
    try {
      setCitas([]);
      setLoading(true);

      const resp = await getCitaRezagadas(derechohabiente.ID_PERSONAL, derechohabiente.ID_BENEFICIARIO, centroMedico.CLAVE);

      if (!resp || !resp.length) return toast.error('No se encontraron citas rezagadas.');

      setCitas(resp.sort((a, b) => {
        const aDate = moment(a.FECHA_CITA).toString().replace('00:00:00', `${a.HORA}:00`);
        const bDate = moment(b.FECHA_CITA).toString().replace('00:00:00', `${b.HORA}:00`);
        if (moment(aDate).isBefore(moment(bDate))) return 1;
        if (moment(aDate).isAfter(moment(bDate))) return -1;
        return 0;
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const optionalStep = async (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      setLoadingCita(true);
      setOpen(true);
      const idCita = e.currentTarget.id;

      const citaEncontrada = citas.find(cita => cita.ID_CITA === parseInt(idCita));

      const configAgendas: number[] = [];
      configAgendas.push(citaEncontrada.ID_CONFIGURACION_AGENDA);

      const referencia: IReferenciaVigente = {
        FECHA_EVOLUCION: '',
        FECHA_REFERENCIA: '',
        ID_REFERENCIA: 0,
        ID_ESPECIALIDAD: citaEncontrada.ESPECIALIDAD.ID_ESPECIALIDAD,
        ESPECIALIDAD: citaEncontrada.ESPECIALIDAD.DESCRIPCION,
        FOLIO_ANIO: 0,
        FOLIO_CONSECUTIVO: 0,
        ID_CENTRO_MEDICO_DESTINO: citaEncontrada.CENTRO_MEDICO.ID_CENTRO_MEDICO,
        CENTRO_MEDICO_DESTINO: citaEncontrada.CENTRO_MEDICO.DESCRIPCION,
        CENTRO_MEDICO_DESTINO_CLAVE: citaEncontrada.CENTRO_MEDICO.CLAVE,
        NUMERO_DIAS_DISPONIBLES: citaEncontrada.CENTRO_MEDICO.NUMERO_DIAS_DISPONIBLES,
        IDS_CONFIGURACIONES_AGENDAS: configAgendas,
        REQ_CITA: 1,
      }

      setCitaSeleccionada(citaEncontrada);
      setReferencia(referencia);

      const search: Partial<IConfiguracionAgenda> = {
        ID_CONFIGURACION_AGENDA: citaEncontrada.ID_CONFIGURACION_AGENDA,
        FECHA_CANCELACION: 'null'
      };
      const isAgendaActiva = await getConfiguracionAgenda(encodeQueryData(search), citaEncontrada.CENTRO_MEDICO.CLAVE);

      if (isAgendaActiva.length > 0) return setAgendaActiva(true);

      if (!isAgendaActiva.some(agenda => agenda.REFERENCIASYREZAGADAS_ENLINEA === 1)) return setAgendaActiva(true);

      const isMedicoActivo = await getMedicoActivo(citaEncontrada.ESPECIALIDAD.ID_ESPECIALIDAD, citaEncontrada.MEDICO_CITA.ID_AREA, citaEncontrada.ID_TURNO, citaEncontrada.CONSULTORIO.ID_CONSULTORIO, citaEncontrada.CENTRO_MEDICO.ID_CENTRO_MEDICO, citaEncontrada.CENTRO_MEDICO.CLAVE);

      if (isMedicoActivo.length > 0) return setAgendaActiva(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCita(false);
    }
  }

  const nextStep = () => {
    removeItemOnce(citas, citaSeleccionada);
    setOpen(false);

    if (citas.length === 0) return setTab(0);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={40} color='primary' />
        </div>
      ) : null}

      {!loading ? (
        <Card elevation={0} style={{ height: '100%' }}>
          <CardHeader
            style={{ padding: 0 }}
            title={
              <>
                Citas rezagadas
                <Tooltip arrow placement='top-start' title={<Typography variant='subtitle2'>Sólo se mostrarán las citas rezagadas subsecuentes, siempre y cuando no tenga una cita actual o futura agendada para la misma especialidad y centro médico.</Typography>}>
                  <IconButton size='small'>
                    <Help />
                  </IconButton>
                </Tooltip>
              </>
            }
            subheader={
              <>
                {citas.length <= 0 ? <Typography>No se encontraron citas rezagadas.</Typography> :
                  <Typography>Sus citas estarán ordenadas por la fecha más proxima.</Typography>}
                <Typography variant='caption'>Para dudas comuniquese a ISSETel (8000 909090).</Typography>
              </>
            }
          />
          <CardContent>
            {citas.length > 0 ? (
              <Grid container spacing={5}>
                {citas.map((cita, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4}>
                    <CardCita
                      cita={cita}
                      optionalStep={optionalStep}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : null}
          </CardContent>
        </Card>
      ) : null}

      {open ? (
        <Dialog fullWidth maxWidth='md' open={open} onClose={() => setOpen(false)}>
          {!loadingCita ? (
            <>
              <DialogTitle>
                <div>
                  <Typography variant='h3' component='h1'>Reagendar cita para la especialidad: {titleCase(citaSeleccionada.ESPECIALIDAD.DESCRIPCION)}</Typography>
                  <Typography variant='body1' color='textSecondary'>Unidad médica: {titleCase(citaSeleccionada.CENTRO_MEDICO.DESCRIPCION)}</Typography>
                </div>
              </DialogTitle>

              <DialogContent>
                {!agendaActiva ? (
                  <DialogContentText variant='body1' color='error' style={{ textAlign: 'center' }}>
                    El consultorio asignado a la última cita no se encuentra activo.
                    <br />
                    Favor de acudir a la unidad médica o comunicarse con ISSETel para reagendar su cita.
                  </DialogContentText>
                ) : (
                  <DialogContentText component='div'>
                    <DisponibilidadReferencia
                      referencia={referencia}
                      setCita={setCitaGuardada}
                      notRefAtAll
                      nextStep={nextStep}
                    />
                  </DialogContentText>
                )}
              </DialogContent>
            </>
          ) : null}
        </Dialog>
      ) : null}
    </div>
  );
};

export default Rezagadas;