import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { Grid, Typography, TextField, InputAdornment, Tooltip, IconButton } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { IContacto } from './interfaces'

interface IContactoProps {
  contacto: IContacto,
  setContacto: Dispatch<SetStateAction<IContacto>>
}

const Contacto: FunctionComponent<IContactoProps> = ({ contacto, setContacto }) => {
  const handleContacto = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (e.target.name === 'telefono' && e.target.value.trim().length <= 10 && !isNaN(Number(e.target.value))) {
      setContacto({ ...contacto, [e.target.name]: e.target.value.trim() });
    }

    if (e.target.name === 'detalle' && e.target.value.trim().length <= 50) {
      setContacto({ ...contacto, [e.target.name]: e.target.value });
    }
  }

  return (
    <>
      <Typography color='error' variant='subtitle2'>Ingrese la información para ponernos en contacto con usted para cualquier situación que se presente con su cita.</Typography>

      <div style={{ marginTop: 15 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              size='small'
              name='telefono'
              color='primary'
              label='Teléfono'
              variant='outlined'
              inputProps={{ max: 10, pattern: "[0-9]{10}" }}
              value={contacto.telefono || ''}
              onChange={handleContacto}
              error={!contacto.telefono || contacto.telefono.trim().length !== 10}
              helperText={!contacto.telefono || contacto.telefono.trim().length !== 10 ? 'El teléfono debe ser de 10 digitos' : ''}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              fullWidth
              size='small'
              name='detalle'
              color='primary'
              label='Detalles del teléfono'
              variant='outlined'
              value={contacto.detalle || ''}
              onChange={handleContacto}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip arrow placement='top-end' title={<Typography variant='subtitle2'>Especifique si el teléfono es de uso personal, laboral, de algún familiar o conocido. De pertenecer a otra persona, escriba la relación que tiene con usted (Mamá, Papá, Vecino(a), Hijo(a) u otro.). Si lo prefiere puede poner un segundo teléfono.</Typography>}>
                      <IconButton size='small'>
                        <Help />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Contacto;