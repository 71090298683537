import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import { Button, CircularProgress, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, List, ListItem, ListItemText, Paper, Radio, RadioGroup, Typography, useTheme } from '@material-ui/core';
import { toast } from 'react-toastify';
import moment from 'moment';
import 'moment/locale/es';
import { getDisponibilidad, saveCita, getCitaConsultaGeneral, getDiasFestivos, getAgendaEnLinea, getCasosEspecialesLocal, getParametrosOnlineByCentroMedico, getEstatusLaboralByTipoTrabajador } from '../services';
import { getNextDay, filterProgramasAtencion } from '../functions';
import { decodeToken, encodeQueryData, enumerateDaysBetweenDates, flatten, getAge, randomNumber, toCapitalize } from '../../../utils/functions';
import { ICentroMedico } from '../../../utils/interfaces';
import { ICitaMedica, IEspecialidad, IProgramaAtencion, IAgendaEnLinea, IParametroOnline, IDisponibilidadAgenda, ICasoEspecialLocal, IFichaCita, IContacto, ITipoTrabajadorAtencion } from '../interfaces';
import Contacto from '../Contacto';
import { PurpleSwitch } from './styles';

moment.locale('es');

interface IDisponibilidadProps {
  especialidades: IEspecialidad[],
  setDatosCita: Dispatch<SetStateAction<ICitaMedica>>,
  setEspecialidades: Dispatch<SetStateAction<IEspecialidad[]>>,
  prevStep: () => any,
  nextStep: () => any
}

const Disponibilidad: FunctionComponent<IDisponibilidadProps> = ({ especialidades, setDatosCita, setEspecialidades, prevStep, nextStep }) => {
  const theme = useTheme();
  const derechohabiente = decodeToken();
  const centroMedico: ICentroMedico = JSON.parse(localStorage.getItem('centroMedico') || sessionStorage.getItem('centroMedico'));
  const [contacto, setContacto] = useState<IContacto>({ telefono: '', detalle: '' });
  const [error, setError] = useState(false);
  const [skipDiscapacidad, setSkipDiscapacidad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingDisponibilidad, setLoadingDisponibilidad] = useState(false);
  const [isDiscapacidad, setIsDiscapacidad] = useState(false);
  const [hourSelected, setHourSelected] = useState('');
  const [fechaCita, setFechaCita] = useState<Date>();
  const [tipoTrabajador, setTipoTrabajador] = useState<ITipoTrabajadorAtencion[]>();
  const [programaSeleccionado, setProgramaSeleccionado] = useState<IProgramaAtencion>();
  const [especialidadSeleccionada, setEspecialidadSeleccionada] = useState<IEspecialidad>();
  const [hours, setHours] = useState<string[]>([]);
  const [availableDays, setAvailableDays] = useState<Date[]>([]);
  const [agendasOnline, setAgendasOnline] = useState<IAgendaEnLinea[]>([]);
  const [programas, setProgramas] = useState<IProgramaAtencion[]>([]);
  const [agendasEspeciales, setAgendasEspeciales] = useState<IAgendaEnLinea[]>([]);
  const [parametros, setParametros] = useState<IParametroOnline[]>([]);
  const [disponibilidades, setDisponibilidades] = useState<IDisponibilidadAgenda[]>([]);
  const [casosEspeciales, setCasosEspeciales] = useState<ICasoEspecialLocal[]>([]);

  const resetForm = () => {
    setHours([]);
    setHourSelected('');
    setIsDiscapacidad(false);
  }

  const handleDate = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setHours([]);
    setHourSelected('');
    setDisponibilidades([]);
    setFechaCita(moment(e.currentTarget.id).toDate());
  }

  const handlePrograma = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    e.preventDefault();
    resetForm();
    setDisponibilidades([]);

    const idPrograma = parseInt(e.currentTarget.dataset.value);
    const programa = programas.find(programa => programa.ID_PROGRAMA_ATENCION === idPrograma);

    if (programaSeleccionado?.ID_PROGRAMA_ATENCION === programa.ID_PROGRAMA_ATENCION) {
      setProgramaSeleccionado(null);
    } else {
      setProgramaSeleccionado(programa);
    }
  }

  const handleDiscapacidad = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHourSelected('');
    setHours([]);
    setDisponibilidades([]);
    setSkipDiscapacidad(false);
    setIsDiscapacidad(e.target.checked);
  }

  const handleSkip = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHourSelected('');
    setHours([]);
    setDisponibilidades([]);
    setSkipDiscapacidad(e.target.checked);
  }

  const getRangeDatesAvailables = (days: number, noLaborales: Date[], canSameDay: boolean = true) => {
    const fecha = moment();
    const maxNextDays = fecha.day() === 6 || fecha.day() === 0 || noLaborales.some(dia => moment(dia).isSame(fecha, 'date')) ? days - 1 : days;

    const maxDate = moment(getNextDay(maxNextDays, noLaborales, fecha.toDate()));

    const dates = enumerateDaysBetweenDates(fecha.toDate(), maxDate.toDate(), noLaborales, {
      excludeFirst: fecha.day() === 6 || fecha.day() === 0 || !canSameDay,
      excludeLast: maxDate.day() === 6 || maxDate.day() === 0
    });

    setAvailableDays(dates);
  }

  const previous = () => {
    const confirm = window.confirm('¿Seguro que desea regresar? Esto borrará cualquier dato que haya seleccionado en este paso.');
    if (confirm) return prevStep();
  }

  const showProgramasAtencion = async (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    e.preventDefault();

    const idEspecialidad = parseInt(e.currentTarget.dataset.value);
    const especialidad = especialidades.find(especialidad => especialidad.ID_ESPECIALIDAD === idEspecialidad);

    resetForm();
    setProgramas([]);
    setProgramaSeleccionado(null);
    setEspecialidadSeleccionada(especialidad);

    // filtrar agendas por la especialidad escogida
    let agendas = agendasOnline.filter(agenda => agenda.especialidad.ID_ESPECIALIDAD === idEspecialidad);
    // obtener el arreglo de programas de atencion
    let respProgramas: IProgramaAtencion[] = flatten(agendas.map(agenda => agenda.programas));
    // eliminar programas duplicados y ordernar alfabeticamente
    respProgramas = respProgramas.filter((programa, index, self) => (
      index === self.findIndex(value => value.DESCRIPCION === programa.DESCRIPCION)
    )).sort((a, b) => {
      if (a.DESCRIPCION < b.DESCRIPCION) return -1;
      if (a.DESCRIPCION > b.DESCRIPCION) return 1;
      return 0;
    });

    setProgramas(filterProgramasAtencion(derechohabiente, respProgramas, casosEspeciales));

    if (casosEspeciales.length > 0) {
      agendas = agendas.filter(agenda => (
        agenda.programas.filter(programa => (
          casosEspeciales.filter(caso => caso.ID_CASO === programa.ID_PROGRAMA_ATENCION).length > 0
        )).length > 0
      ));
    }

    setAgendasEspeciales(agendas);
  }

  const consultarDisponibilidad = async () => {
    try {
      setHours([]);
      setError(false);
      setHourSelected('');
      setDisponibilidades([]);
      setLoadingDisponibilidad(true);

      if (!especialidadSeleccionada || !fechaCita) return setError(true);

      const edad = getAge(derechohabiente.FECHA_NACIMIENTO);
      const isDiscapacidadLocal = edad >= 65 && !skipDiscapacidad;

      let agendas = agendasOnline.filter(agenda => agenda.especialidad.ID_ESPECIALIDAD === especialidadSeleccionada?.ID_ESPECIALIDAD);

      if ((isDiscapacidad || isDiscapacidadLocal) && edad >= 65 && !skipDiscapacidad && agendasEspeciales.length && !programaSeleccionado) {
        // la persona es mayor de edad (por tanto discapacitada) y existen agendas especiales y no quiere buscar en planta alta y no ha seleccionado programa de atencion
        agendas = agendasEspeciales.filter(agenda => (
          agenda.programas.filter(programa => (
            programa.EDAD_MINIMA > getAge(derechohabiente.FECHA_NACIMIENTO)
          ))
        ));
      } else if (isDiscapacidad && !skipDiscapacidad && agendasEspeciales.length && !programaSeleccionado) {
        // la persona es discapacitad y no quiere buscar en planta alta y hay agendas especiales y no ha seleccionado programa de atención
        agendas = agendasEspeciales.filter(agenda => (
          agenda.programas.filter(programa => (
            programa.EDAD_MINIMA <= getAge(derechohabiente.FECHA_NACIMIENTO)
          ))
        ));
      } else if (programaSeleccionado) {
        // la persona selecciono un programa de atencion
        agendas = agendas.filter(agenda => (
          agenda.programas.filter(programa => (
            programa.ID_PROGRAMA_ATENCION === programaSeleccionado.ID_PROGRAMA_ATENCION
          )).length > 0
        ));
      } else {
        // la persona selecciono una especialidad y quiere buscar en planta alta o no es discapacitada
        agendas = agendas.filter(agenda => agenda.EXCLUSIVO_PROG_ATENCION === 0);
      }

      const idsAgendas = agendas.map(agenda => agenda.ID_CONFIGURACION_AGENDA);
      const fechaInicio = moment(fechaCita).format('DD/MM/YYYY');
      const fechaFin = moment(fechaCita).format('DD/MM/YYYY');

      const minutes = parametros.find(parametro => parametro.NOMBRE_PARAMETRO === 'Mins tolerancia para agendar (Si 4 es true) ');

      // TODO: Consultar citas agendadas
      const sameDay = parametros.find(parametro => parametro.NOMBRE_PARAMETRO === 'Agendar mismo dia');
      if (sameDay.ACTIVO === 1 && sameDay.VALOR === "false" && moment().isSame(fechaCita, 'date')) toast.error('No puede agendar el mismo día');

      let idsTiposAtencion = null;

      if (tipoTrabajador && tipoTrabajador.length && centroMedico.CLAVE === 'UMFC' && especialidadSeleccionada.ID_ESPECIALIDAD === 35 &&
        !programaSeleccionado) {
        idsTiposAtencion = tipoTrabajador[0].TIPOS_ATENCION;
      }

      let fichas: { data: IDisponibilidadAgenda[] } = await getDisponibilidad(idsAgendas, centroMedico.CLAVE, fechaInicio, fechaFin, idsTiposAtencion, true);

      fichas.data = fichas.data.filter(ficha =>
        ficha.disponibilidad.filter(disp => {
          const horas = disp.horas.filter(hora => {
            const date = fechaCita.toString().replace('00:00:00', `${hora.hora}:00`);
            if (moment(new Date()).add(parseInt(minutes.VALOR), 'minutes').isBefore(moment(date))) return hora;
            return null;
          }).filter(x => x);
          disp.horas = horas;
          return horas.length > 0
        }).length > 0
      );

      if (fichas.data.length <= 0) return toast.error('No se encontraron citas disponibles');

      const hoursToShow = Array.from(
        new Set(flatten(
          fichas.data.map(ficha =>
            ficha.disponibilidad.map(disp => disp.horas.map(hora => hora.hora))
          )) as string[]
        )).sort((a, b) => {
          if (moment(a, 'H:mm').isBefore(moment(b, 'H:mm'))) return -1;
          if (moment(a, 'H:mm').isAfter(moment(b, 'H:mm'))) return 1;
          return 0;
        });

      setDisponibilidades(fichas.data);
      setHours(hoursToShow);
    } catch (error) {
      toast.error(`No se ha podido establecer conexión con el servidor de ${centroMedico.DESCRIPCION}.`);
      toast.error(error);
    } finally {
      setLoadingDisponibilidad(false);
    }
  }

  const validateAndNext = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!hourSelected) return toast.error('Seleccione la hora de su cita');

    const date = fechaCita.toString().replace('00:00:00', `${hourSelected}:00`);
    const minutes = parametros.find(parametro => parametro.NOMBRE_PARAMETRO === 'Mins tolerancia para agendar (Si 4 es true) ');
    const sameDay = parametros.find(parametro => parametro.NOMBRE_PARAMETRO === 'Agendar mismo dia');

    if (sameDay.ACTIVO === 1 && sameDay.VALOR === "false" && moment().isSame(fechaCita, 'date')) toast.error('No puede agendar el mismo día');

    if (moment(date).subtract(parseInt(minutes.VALOR), 'minutes').isBefore(moment())) return toast.error(`Su cita debe ser con ${minutes.VALOR} minutos de anticipación.`);

    setLoading(true);

    try {
      let found = disponibilidades.filter(disponibilidad =>
        disponibilidad.disponibilidad.filter(disp =>
          disp.horas.filter(hora => hora.hora === hourSelected).length > 0
        ).length > 0
      );

      const random = randomNumber(found.length - 1);

      const hour = found[random].disponibilidad.map(disp =>
        disp.horas.filter(hora => hora.hora === hourSelected)[0]
      )[0];

      const idBeneficiario = derechohabiente?.ID_BENEFICIARIO || null;
      const data: Partial<IFichaCita> = {
        FECHA_CITA: moment(fechaCita).format('DD/MM/YYYY'),
        HORA: hourSelected,
        FICHA: hour.ficha,
        ID_TIPO_ATENCION: hour.idTipoAtencion,
        ID_PERSONAL: derechohabiente.ID_PERSONAL,
        NUMERO_CUENTA: derechohabiente.NUMERO_CUENTA,
        ID_MEDICO_CITA: found[random].medico.idMedico,
        ID_CENTRO_MEDICO: centroMedico.ID_CENTRO_MEDICO,
        ID_PACIENTE_BENEFICIARIO: idBeneficiario,
        ID_PROGRAMA_ATENCION: programaSeleccionado?.ID_PROGRAMA_ATENCION || null,
        ID_PROPIEDADES_CONFIG_AGENDA: found[random].propiedadesConfiguracionAgenda.ID_PROPIEDADES_CONFIG_AGENDA,
        ENLINEA: 1,
        TELEFONO: contacto.telefono,
        DETALLE_TELEFONO: contacto.detalle
      };

      const citaSaved = await saveCita(centroMedico.CLAVE, data, null, { isCitaOnline: true });

      if (!citaSaved.saved) {
        setLoading(false);
        return toast.error(citaSaved.message);
      }

      toast.success(citaSaved.message);

      const cita = await getCitaConsultaGeneral(encodeQueryData({ ...data, HORA: null }), centroMedico.CLAVE, { isCitaReferencia: false });

      setDatosCita(cita[0]);
      setLoading(false);

      nextStep();
    } catch (error) {
      toast.error(`No se ha podido establecer conexión con el servidor de ${centroMedico.DESCRIPCION}.`);
      console.error(error);
    }
  }

  useEffect(() => {
    const getData = async () => {
      try {
        setLoadingPage(true);

        const noLaborales = await getDiasFestivos(moment().year(), derechohabiente.NUMERO_CUENTA, centroMedico.ID_CENTRO_MEDICO);
        const noFestivos = flatten(
          noLaborales.map(dia => {
            const fechaInicio = moment(dia.fechaInicio, 'DD/MM/YYYY').toDate();
            const fechaFin = moment(dia.fechaFin, 'DD/MM/YYYY').toDate();
            return enumerateDaysBetweenDates(fechaInicio, fechaFin);
          })
        ) as Date[];

        const agendas = await getAgendaEnLinea(centroMedico.ID_CENTRO_MEDICO, centroMedico.CLAVE, 1);
        // eliminar especialidades duplicadas y order alfabeticamente
        const respEspecialidades = agendas.map(agenda => agenda.especialidad)
          .filter((especialidad, index, self) => index === self.findIndex(value =>
            value.DESCRIPCION === especialidad.DESCRIPCION
          )).sort((a, b) => {
            if (a.DESCRIPCION < b.DESCRIPCION) return -1;
            if (a.DESCRIPCION > b.DESCRIPCION) return 1;
            return 0;
          });

        setTipoTrabajador(await getEstatusLaboralByTipoTrabajador(encodeQueryData({
          rfc: derechohabiente.RFC,
          numeroCuenta: derechohabiente.NUMERO_CUENTA,
          idBeneficiario: derechohabiente.ID_BENEFICIARIO || 0,
        })));
        setAgendasOnline(agendas);
        setEspecialidades(respEspecialidades);
        setCasosEspeciales(await getCasosEspecialesLocal());

        const paramsResp = await getParametrosOnlineByCentroMedico(centroMedico.ID_CENTRO_MEDICO);
        setParametros(paramsResp);

        const sameDay = paramsResp.find(parametro => parametro.NOMBRE_PARAMETRO === 'Agendar mismo dia');
        const nextDaysAvailables = paramsResp.find(parametro => parametro.NOMBRE_PARAMETRO === 'Días naturales max. para agendar hacia adelante');

        if (nextDaysAvailables.ACTIVO === 1) {
          const canSameDay = sameDay.ACTIVO === 1 && sameDay.VALOR === "true";
          getRangeDatesAvailables(parseInt(nextDaysAvailables.VALOR), noFestivos, canSameDay);
        }
      } catch (error) {
        toast.error(`No se ha podido establecer conexión con el servidor de ${centroMedico.DESCRIPCION}.`);
        console.error(error);
      } finally {
        setLoadingPage(false);
      }
    }

    getData();
  }, [centroMedico.CLAVE, centroMedico.DESCRIPCION, centroMedico.ID_CENTRO_MEDICO, derechohabiente.ES_BENEFICIARIO, derechohabiente.NUMERO_CUENTA, derechohabiente.RFC, setEspecialidades]);

  if (loadingPage) return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <CircularProgress size={50} color='primary' />
      <Typography color='textSecondary' style={{ marginTop: theme.spacing(3) }}>Está operación puede tardar unos minutos, por favor espere.</Typography>
    </div>
  )

  if (agendasOnline.length <= 0) return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Typography color='error'>No existen consultorios para cita en línea.</Typography>
    </div>
  )

  return (
    <Grid container spacing={2} style={{ marginTop: 15 }}>
      <Grid item xs={12}>
        <Contacto contacto={contacto} setContacto={setContacto} />
      </Grid>

      {contacto.telefono && contacto.telefono.trim().length === 10 ? (
        <Grid item xs={12}>
          <form onSubmit={validateAndNext} style={{ marginTop: 15 }}>
            <Grid container spacing={3} alignItems='flex-start'>
              <Grid item xs={12} sm={6}>
                {availableDays && availableDays.length ?
                  <FormControl fullWidth>
                    <FormLabel error={error && !fechaCita}>Fecha de la cita*</FormLabel>
                    <FormGroup style={{ marginTop: 15 }}>
                      <Paper style={{ overflow: 'hidden' }}>
                        <List dense disablePadding>
                          {availableDays.map((dia, index) =>
                            <ListItem
                              button
                              divider
                              key={index}
                              onClick={handleDate}
                              id={moment(dia).toString()}
                              disabled={loadingDisponibilidad || loading}
                              style={{
                                color: moment(dia).isSame(moment(fechaCita)) ? '#fff' : '',
                                backgroundColor: moment(dia).isSame(moment(fechaCita)) ? theme.palette.primary.main : ''
                              }}
                            >
                              <ListItemText primary={toCapitalize(moment(dia, 'DD/MM/YYYY').format('dddd, DD [de] MMMM [de] YYYY'))} />
                            </ListItem>
                          )}
                        </List>
                      </Paper>
                      {error && !fechaCita ? <FormHelperText error={error && !fechaCita}>Debe seleccionar la fecha de su cita</FormHelperText> : null}
                    </FormGroup>
                  </FormControl> : null}
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                {especialidades.length > 0 ? (
                  <FormControl error={error && !especialidadSeleccionada} >
                    <FormLabel>Servicio*</FormLabel>
                    <RadioGroup aria-label='turno' name='servicio' value={especialidadSeleccionada?.ID_ESPECIALIDAD || 0}>
                      {especialidades.map((especialidad, index) => (
                        <FormControlLabel
                          key={index}
                          name={especialidad.DESCRIPCION}
                          data-value={especialidad.ID_ESPECIALIDAD.toString()}
                          onClick={showProgramasAtencion}
                          control={<Radio color='primary' />}
                          value={especialidad.ID_ESPECIALIDAD}
                          disabled={loadingDisponibilidad || loading}
                          label={toCapitalize(especialidad.DESCRIPCION)}
                        />
                      ))}
                    </RadioGroup>
                    {/* // ? Este mensja se muestra solo para odontologia en UMF Centro, de momento es manual */}
                    {/* // TODO: El mensaje debe venir de la base de datos */}
                    {especialidadSeleccionada && especialidadSeleccionada.ID_ESPECIALIDAD === 21 && centroMedico.CLAVE === 'UMFC' ? <FormHelperText>Solo se atenderán urgencias dentales (dolor dental intenso, sangrado abundante de las encías, abscesos, fracturas de coronas o de prótesis, etc.)</FormHelperText> : null}
                    {error && !especialidadSeleccionada ? <FormHelperText>Debe seleccionar el servicio</FormHelperText> : null}
                  </FormControl>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                {programas.length > 0 ? (
                  <FormControl>
                    <FormLabel>Programa de atencion*</FormLabel>
                    <RadioGroup
                      name='programa'
                      aria-label='programa'
                      value={programaSeleccionado?.ID_PROGRAMA_ATENCION || 0}
                    >
                      {programas.map((programa, index) => (
                        <FormControlLabel
                          key={index}
                          data-value={programa.ID_PROGRAMA_ATENCION.toString()}
                          onClick={handlePrograma}
                          disabled={loadingDisponibilidad || loading}
                          name={programa.DESCRIPCION}
                          value={programa.ID_PROGRAMA_ATENCION}
                          control={<Radio color='primary' />}
                          label={toCapitalize(programa.DESCRIPCION)}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                ) : null}
              </Grid>

              {/* disponible solo para umf centro */}
              {getAge(derechohabiente.FECHA_NACIMIENTO) < 65 && centroMedico.CLAVE === 'UMFC' &&
                especialidadSeleccionada?.ID_ESPECIALIDAD === 35 && !programaSeleccionado &&
                derechohabiente.ID_SERVICIO_MEDICO === 4 ? (
                <Grid item xs={12}>
                  <Typography color='textSecondary' variant='body2'>Los pacientes que presenten alguna discapacidad a nivel motriz o visual (temporal o permanente), así como los pacientes de la tercera edad serán dirigidos a un consultorio de planta baja, con el fin de facilitar su desplazamiento en la unidad.</Typography>
                  <FormControl>
                    <FormGroup>
                      <FormControlLabel
                        name='isDiscapacidad'
                        disabled={loadingDisponibilidad || loading}
                        label='¿El paciente presenta alguna discapacidad motora o visual (temporal o permanente)?'
                        control={
                          <PurpleSwitch
                            checked={isDiscapacidad}
                            onChange={handleDiscapacidad}
                          />
                        }
                        labelPlacement='start'
                        style={{ marginLeft: 0 }}
                        value={isDiscapacidad}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              ) : null}

              {/* disponible solo para umf centro */}
              {(getAge(derechohabiente.FECHA_NACIMIENTO) >= 65 || isDiscapacidad) && derechohabiente.ID_SERVICIO_MEDICO === 4 ? (
                <Grid item xs={12}>
                  <Typography color='textSecondary' variant='body2'>Si no hubiera fichas disponibles en la fecha seleccionada en ninguno de los turnos y desea buscar disponibilidad en consultorios de planta alta, marque la siguiente casilla y presione CONSULTAR DISPONIBILIDAD para ampliar la búsqueda</Typography>
                  <FormControl>
                    <FormGroup>
                      <FormControlLabel
                        name='skipDiscapacidad'
                        disabled={loadingDisponibilidad || loading}
                        label='Deseo buscar disponibilidad en consultorios de planta alta'
                        control={
                          <PurpleSwitch
                            checked={skipDiscapacidad}
                            onChange={handleSkip}
                          />
                        }
                        labelPlacement='start'
                        style={{ marginLeft: 0 }}
                        value={skipDiscapacidad}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              ) : null}

              <Grid item xs={12} container spacing={2}>
                {!loading ? (
                  <Grid item xs={12}>
                    <Button
                      size='small'
                      color='primary'
                      variant='outlined'
                      onClick={() => consultarDisponibilidad()}
                      disabled={loadingDisponibilidad || loading}
                      startIcon={!loadingDisponibilidad ? null : <CircularProgress size={20} color='inherit' />}
                    >
                      {!loadingDisponibilidad ? 'Consultar disponibilidad' : 'Consultando disponibilidad'}
                    </Button>
                  </Grid>
                ) : null}
                {hours.length === 0 ? null :
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={1}
                    justify='flex-start'
                    style={{ display: !loadingDisponibilidad ? 'flex' : 'none' }}
                  >
                    {hours.map((hour, index) =>
                      <Grid key={index} item sm={2} md={1}>
                        <Button
                          color='primary'
                          disabled={loading}
                          variant={hourSelected === hour ? 'contained' : 'text'}
                          onClick={(e) => setHourSelected(hour)}
                        >{hour}</Button>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography variant='caption' color='error'>* Seleccione la hora de su cita</Typography>
                    </Grid>
                  </Grid>
                }
              </Grid>
              <Grid item xs={12}>
                {/* <Button
                  variant='contained'
                  disabled={loading || loadingDisponibilidad}
                  onClick={previous}
                >Atrás</Button> */}
                {hourSelected ? (
                  <Button
                    type='submit'
                    color='primary'
                    variant='contained'
                    disabled={loading}
                    style={{ marginLeft: 15 }}
                    startIcon={loading ? <CircularProgress size={20} color='inherit' /> : null}
                  >
                    {!loading ? 'Siguiente' : 'Guardando datos'}
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </form>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default Disponibilidad;