import React, { FunctionComponent, useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { Card, CardHeader, IconButton, Typography, CardContent, Grid, CircularProgress } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import CardCita from './CardCita';
import HelpCardCita from './HelpCardCita';
import { toast } from 'react-toastify';
import { decodeToken, decodeCentroMedico, encodeQueryData } from '../../../utils/functions';
import { ICitaMedica, IParametroOnline, IPortalParametroGlobal } from '../interfaces';
import { getCitasReferencias, getCitaConsultaGeneral, getParametrosOnlineByCentroMedico, deleteCitaMedica } from '../services';

moment.locale('es');

interface IAgendadasProps {
  parametrosGlobales: IPortalParametroGlobal[]
}

const Agendadas: FunctionComponent<IAgendadasProps> = ({ parametrosGlobales }) => {
  const derechohabiente = decodeToken();
  const centroMedico = decodeCentroMedico();
  const [loading, setLoading] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);
  const [citas, setCitas] = useState<ICitaMedica[]>([]);
  const [parametros, setParametros] = useState<IParametroOnline[]>([]);

  const getData = async () => {
    try {
      setCitas([]);
      setLoading(true);

      const search: Partial<ICitaMedica> = {
        ID_PERSONAL: derechohabiente.ID_PERSONAL,
        ID_PACIENTE_BENEFICIARIO: derechohabiente?.ID_BENEFICIARIO || 0,
        SE_PRESENTO: 0
      }

      // TODO: habilitar busqueda de citas de consulta general y de referencias
      const respReferencias = await getCitasReferencias(encodeQueryData(search), centroMedico.CLAVE, {
        fechaCitaInicio: moment().format('DD/MM/YYYY'),
        isCitaReferencia: true
      }) || [];

      const respConsultaGeneral = await getCitaConsultaGeneral(encodeQueryData(search), centroMedico.CLAVE, {
        fechaCitaInicio: moment().format('DD/MM/YYYY'),
        isCitaReferencia: false
      }) || [];

      // TODO: habilitar las citas de referencia concatenacion
      const resp = respConsultaGeneral.length || respReferencias.length ? respConsultaGeneral.concat(respReferencias) : [];

      if (!resp || !resp.length) return toast.error('No se encontraron citas agendadas.');

      const params = await getParametrosOnlineByCentroMedico(centroMedico.ID_CENTRO_MEDICO);

      setCitas(resp.sort((a, b) => {
        const aDate = moment(a.FECHA_CITA).toString().replace('00:00:00', `${a.HORA}:00`);
        const bDate = moment(b.FECHA_CITA).toString().replace('00:00:00', `${b.HORA}:00`);
        if (moment(aDate).isBefore(moment(bDate))) return -1;
        if (moment(aDate).isAfter(moment(bDate))) return 1;
        return 0;
      }));

      setParametros(params);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const finalStep = async (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      const cita = citas.find(cita => cita.ID_CITA === parseInt(e.currentTarget.id));
      const date = moment(cita.FECHA_CITA).toString().replace('00:00:00', `${cita.HORA}:00`);
      const sameDay = parametros.find(param => param.NOMBRE_PARAMETRO === 'Cancelar mismo dia');
      const minutes = parametros.find(param => param.NOMBRE_PARAMETRO === 'Tolerancia x canc en mins. ((Aplica si 5 es true)');
      const diasCancelarReagendada = parametrosGlobales.find(p => p.NOMBRE_PARAMETRO === 'Dias para cancelar cita rezagada' && p.ACTIVO === 1 && p.VALOR !== '0');
      let canDelete = true;

      if (cita.ENLINEA === 1 && cita.ID_TIPO_ATENCION === 2) {
        if (moment(date).isBefore(moment().subtract(parseInt(diasCancelarReagendada.VALOR), 'days'))) {
          canDelete = false;
          return toast.error(`No puede cancelar una cita con menos de ${diasCancelarReagendada.VALOR} días de anticipación.`);
        }
      } else {
        if (
          sameDay.ACTIVO === 1 && sameDay.VALOR === "false" &&
          moment(cita.FECHA_CITA).isSame(moment(), 'date')
        ) {
          canDelete = false;
          return toast.error('No puede cancelar citas agendadas para hoy.');
        }

        if (moment(date).isBefore(moment().add(parseInt(minutes.VALOR), 'minutes'))) {
          canDelete = false;
          return toast.error(`No puede cancelar una cita para hoy con menos de ${minutes.VALOR} minutos de anticipación.`);
        }
      }

      if (canDelete) {
        const confirm = window.confirm('¿Estás seguro que deseas cancelar tu cita? Está acción es permanente.');
        if (!confirm) return;

        setLoading(true);

        const deleted = await deleteCitaMedica(cita.ID_CITA, centroMedico.CLAVE);
        if (!deleted) toast.error('No fue posible eliminar la cita');

        toast.success('Cita cancelada');

        getData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={40} color='primary' />
        </div>
      ) : null}

      {!loading ? (
        <Card elevation={0} style={{ height: '100%' }}>
          <CardHeader
            style={{ padding: 0 }}
            title={
              <>
                Citas agendadas
                <IconButton size='small' onClick={() => setOpenHelp(true)}>
                  <Help />
                </IconButton>
              </>
            }
            subheader={
              <>
                {citas.length <= 0 ? <Typography>No se encontraron citas agendadas.</Typography> :
                  <Typography>Sus citas estarán ordenadas por la fecha más proxima.</Typography>}
                <Typography variant='caption'>Si alguna cita no aparece, consulte más tarde mientras los datos son actualizados o comuniquese a ISSETel (8000 909090).</Typography>
              </>
            }
          />
          <CardContent>
            {citas.length > 0 ? (
              <Grid container spacing={5}>
                {citas.map((cita, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4}>
                    <CardCita
                      cita={cita}
                      finalStep={
                        (cita.ENLINEA === 1 && cita.ID_TIPO_ATENCION !== 2 && !cita.REFERENCIA) ? finalStep : cita.ENLINEA === 1 && cita.ID_TIPO_ATENCION === 2 && parametrosGlobales.some(p => p.NOMBRE_PARAMETRO === 'Cancelar rezagadas' && p.ACTIVO === 1 && p.VALOR === 'true') ? finalStep : null
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            ) : null}
            <HelpCardCita open={openHelp} setOpen={setOpenHelp} />
          </CardContent>
        </Card>
      ) : null}
    </div>
  );
};

export default Agendadas;