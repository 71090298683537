import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { Button, CircularProgress, FormControl, FormGroup, FormHelperText, FormLabel, Grid, List, ListItem, ListItemText, Paper, Typography, useTheme } from '@material-ui/core';
import { IReferenciaVigente } from './interfaces';
import { ICitaMedica, IContacto, IDisponibilidadAgenda, IFichaCita } from '../interfaces';
import { getCitasReferencias, getDisponibilidad, getParamsRefByCentroMedico, saveCita } from '../services';
import { toast } from 'react-toastify';
import { decodeToken, encodeQueryData, flatten, toCapitalize } from '../../../utils/functions';
import Contacto from '../Contacto';

moment.locale('es');
interface IDisponibilidadReferenciaProps {
  referencia: IReferenciaVigente,
  setCita: Dispatch<SetStateAction<ICitaMedica>>,
  notRefAtAll?: boolean,
  prevStep?: () => any,
  nextStep?: () => any
}

const DisponibilidadReferencia: FunctionComponent<IDisponibilidadReferenciaProps> = ({ referencia, setCita, notRefAtAll, prevStep, nextStep }) => {
  const theme = useTheme();
  const derechohabiente = decodeToken();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [horaSeleccionada, setHoraSeleccionada] = useState('');
  const [fechaSeleccionada, setFechaSeleccionada] = useState('');
  const [contacto, setContacto] = useState<IContacto>({ telefono: '', detalle: '' });
  const [disponibilidades, setDisponibilidades] = useState<IDisponibilidadAgenda[]>([]);
  const [fechas, setFechas] = useState<string[]>([]);
  const [horas, setHoras] = useState<string[]>([]);

  const handleFecha = (fecha: string) => {
    try {
      setError(false);

      setHoras([]);
      setHoraSeleccionada('');

      const hoursToShow: string[] = Array.from(
        new Set(flatten(
          disponibilidades.map(ficha =>
            ficha.disponibilidad.map(disp => {
              if (moment(disp.fecha, 'DD/MM/YYYY').isSame(moment(fecha, 'DD/MM/YYYY'), 'date')) return disp.horas.map(hora => hora.hora);
              return null;
            }).filter(x => x)
          )) as string[]
        )).sort((a, b) => {
          if (moment(a, 'H:mm').isBefore(moment(b, 'H:mm'))) return -1;
          if (moment(a, 'H:mm').isAfter(moment(b, 'H:mm'))) return 1;
          return 0;
        });

      setFechaSeleccionada(fecha);
      setHoras(hoursToShow);
    } catch (error) {
      console.error(error);
    }
  }

  const validateAndNext = async () => {
    try {
      setError(false);
      setLoadingSave(true);

      if (!fechaSeleccionada || !horaSeleccionada) return setError(true);

      // obtener agendas en la fecha y hora seleccionada
      let max = 0;
      const disponibilidadesEncontradas = disponibilidades.filter(disponibilidad =>
        disponibilidad.disponibilidad.filter(disp => {
          if (moment(disp.fecha, 'DD/MM/YYYY').isSame(moment(fechaSeleccionada, 'DD/MM/YYYY'), 'date')) {
            return disp.horas.filter(hora => {
              const dateSeleccionada = moment(fechaSeleccionada, 'DD/MM/YYYY').toString().replace('00:00:00', `${horaSeleccionada}:00`);
              const date = moment(fechaSeleccionada, 'DD/MM/YYYY').toString().replace('00:00:00', `${hora.hora}:00`);
              const isSame = moment(date).isSame(moment(dateSeleccionada));
              if (isSame) max = disp.horas.length > max ? disp.horas.length : max;
              return isSame
            }).length > 0;
          }
        }).length > 0
      );

      // obtener la agenda con mayor cantidad de fichas disponibles
      const disponibilidadSeleccionada = disponibilidadesEncontradas.filter(disponibilidad =>
        disponibilidad.disponibilidad.filter(disp => {
          if (moment(disp.fecha, 'DD/MM/YYYY').isSame(moment(fechaSeleccionada, 'DD/MM/YYYY'), 'date')) return disp.horas.length === max;
        }).length > 0
      )[0];

      // obtener la hora y ficha de la agenda seleccionada
      const hora = disponibilidadSeleccionada.disponibilidad.map(disp => {
        if (moment(disp.fecha, 'DD/MM/YYYY').isSame(moment(fechaSeleccionada, 'DD/MM/YYYY'), 'date')) return disp.horas.find(hora => hora.hora === horaSeleccionada);
      }).find(x => x);

      const data: Partial<IFichaCita> = {
        FECHA_CITA: moment(fechaSeleccionada, 'DD/MM/YYYY').format('DD/MM/YYYY'),
        HORA: horaSeleccionada,
        FICHA: hora.ficha,
        ID_TIPO_ATENCION: !notRefAtAll ? 1 : 2,
        ID_PERSONAL: derechohabiente.ID_PERSONAL,
        NUMERO_CUENTA: derechohabiente.NUMERO_CUENTA,
        ID_MEDICO_CITA: disponibilidadSeleccionada.medico.idMedico,
        ID_CENTRO_MEDICO: referencia.ID_CENTRO_MEDICO_DESTINO,
        ID_PACIENTE_BENEFICIARIO: derechohabiente?.ID_BENEFICIARIO || null,
        ID_PROPIEDADES_CONFIG_AGENDA: disponibilidadSeleccionada.propiedadesConfiguracionAgenda.ID_PROPIEDADES_CONFIG_AGENDA,
        ENLINEA: 1,
        TELEFONO: contacto.telefono,
        DETALLE_TELEFONO: contacto.detalle
      };

      let citaSaved = null;

      citaSaved = !notRefAtAll ? await saveCita(referencia.CENTRO_MEDICO_DESTINO_CLAVE, data, { ID_REFERENCIA: referencia.ID_REFERENCIA, ID_ESPECIALIDAD: disponibilidadSeleccionada.especialidad.ID_ESPECIALIDAD }, { isCitaReferencia: true }) : await saveCita(referencia.CENTRO_MEDICO_DESTINO_CLAVE, data, null, null);

      if (!citaSaved.saved) {
        setLoadingSave(false);
        return toast.error(citaSaved.message);
      }

      toast.success(citaSaved.message);

      const cita = await getCitasReferencias(encodeQueryData({ ...data, HORA: null }), referencia.CENTRO_MEDICO_DESTINO_CLAVE, { isCitaReferencia: true });

      setCita(cita[0]);
      setLoadingSave(false);

      if (nextStep) nextStep();
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSave(false)
    }
  }

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        setHoras([]);
        setDisponibilidades([]);
        setFechaSeleccionada('');
        setHoraSeleccionada('');

        const today = moment();
        const respParams = await getParamsRefByCentroMedico(referencia.ID_CENTRO_MEDICO_DESTINO);

        if (respParams && respParams.length > 0) {
          const days = respParams.find(param => param.NOMBRE_PARAMETRO === 'Dias nat min agendar');
          const minutes = respParams.find(param => param.NOMBRE_PARAMETRO === 'Tolerancia mismo dia (min)');

          if (parseInt(days.VALOR) === 0 && days.ACTIVO === 1 && minutes.ACTIVO === 1) today.add(parseInt(minutes.VALOR), 'minutes');
          if (parseInt(days.VALOR) > 0 && days.ACTIVO === 1) {
            today.add(parseInt(days.VALOR), 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
          }
        }

        let fichas: { data: IDisponibilidadAgenda[] } = await getDisponibilidad(referencia.IDS_CONFIGURACIONES_AGENDAS, referencia.CENTRO_MEDICO_DESTINO_CLAVE, today.format('DD/MM/YYYY'), null, [!notRefAtAll ? 1 : 2], false, !notRefAtAll ? true : false);

        fichas.data = fichas.data.filter(ficha =>
          ficha.disponibilidad.filter((disp, index) => {
            const horas = disp.horas.filter(hora => {
              const date = moment(disp.fecha, 'DD/MM/YYYY').toDate().toString().replace('00:00:00', `${hora.hora}:00`);
              if (today.isSameOrBefore(moment(date))) return hora;
              return null;
            });

            disp.horas = horas.filter(x => x);
            if (!horas.length) ficha.disponibilidad.splice(index, 1);
            return horas.length > 0
          }).length > 0
        );

        if (!fichas.data.length) return toast.error('No se encontraron fichas disponibles.');

        const datesToShow: string[] = Array.from(
          new Set(flatten(fichas.data.map(ficha =>
            ficha.disponibilidad.map(disp => disp.fecha)
          )))
        ).sort((a, b) => {
          if (moment(a, 'DD/MM/YYYY').isBefore(moment(b, 'DD/MM/YYYY'))) return -1;
          if (moment(a, 'DD/MM/YYYY').isAfter(moment(b, 'DD/MM/YYYY'))) return 1;
          return 0;
        }).slice(0, referencia.NUMERO_DIAS_DISPONIBLES);

        setDisponibilidades(fichas.data);
        setFechas(datesToShow);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    getData();
  }, [notRefAtAll, referencia.CENTRO_MEDICO_DESTINO_CLAVE, referencia.IDS_CONFIGURACIONES_AGENDAS, referencia.ID_CENTRO_MEDICO_DESTINO, referencia.NUMERO_DIAS_DISPONIBLES])


  if (loading) return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress size={50} color='primary' />
    </div>
  )

  return (
    <Grid container spacing={3} style={{ marginTop: 15 }}>
      {!loading && !disponibilidades.length ? (
        <Grid item xs={12}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography color='error'>No se encontraron citas disponibles para la especialidad</Typography>
          </div>
        </Grid>
      ) : null}

      {!loading && disponibilidades.length ? (
        <>
          <Grid item xs={12}>
            <Contacto contacto={contacto} setContacto={setContacto} />
          </Grid>

          {contacto.telefono && contacto.telefono.trim().length === 10 ? (
            <>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel error={error && !fechaSeleccionada}>Fecha de su cita*</FormLabel>
                  <FormGroup style={{ marginTop: 15 }}>
                    <Paper elevation={1}>
                      <List dense disablePadding>
                        {fechas.map((fecha, index) => (
                          <ListItem
                            key={index}
                            divider
                            button
                            onClick={() => handleFecha(fecha)}
                            style={{ backgroundColor: moment(fechaSeleccionada, 'DD/MM/YYYY').isSame(moment(fecha, 'DD/MM/YYYY')) ? theme.palette.primary.main : '#fff', color: moment(fechaSeleccionada, 'DD/MM/YYYY').isSame(moment(fecha, 'DD/MM/YYYY')) ? '#fff' : '#333' }}
                          >
                            <ListItemText>{toCapitalize(moment(fecha, 'DD/MM/YYYY').format('dddd, DD [de] MMMM [de] YYYY'))}</ListItemText>
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  </FormGroup>
                  <FormHelperText color={error && !fechaSeleccionada ? 'error' : 'primary'}>Seleccione la fecha de su cita</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={8}>
                {horas.length ? (
                  <FormControl>
                    <FormLabel error={error && !horaSeleccionada}>Hora de la cita*</FormLabel>
                    <FormGroup style={{ marginTop: 15 }}>
                      <Grid container spacing={1} alignContent='flex-start' justify='flex-start'>
                        {horas.map((hora, index) => (
                          <Grid key={index} item sm={3}>
                            <Button
                              color='primary'
                              disabled={loading}
                              variant={horaSeleccionada === hora ? 'contained' : 'text'}
                              onClick={(e) => setHoraSeleccionada(hora)}
                            >{hora}</Button>
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                    <FormHelperText color={error && !horaSeleccionada ? 'error' : 'primary'}>Seleccione la hora de su cita</FormHelperText>
                  </FormControl>
                ) : null}
              </Grid>

              <Grid item xs={12} style={{ marginTop: theme.spacing(2) }}>
                {prevStep ? <Button
                  variant='contained'
                  disabled={loadingSave}
                  onClick={prevStep}
                >Atrás</Button> : null}
                {!loading && disponibilidades.length > 0 ? (
                  <Button
                    color='primary'
                    variant='contained'
                    disabled={loadingSave}
                    onClick={validateAndNext}
                    style={{ marginLeft: 15 }}
                    startIcon={loadingSave ? <CircularProgress size={20} color='inherit' /> : null}
                  >
                    {!loadingSave ? 'Siguiente' : 'Guardando datos'}
                  </Button>
                ) : null}
              </Grid>
            </>
          ) : null}
        </>
      ) : null}

    </Grid>
  );
};

export default DisponibilidadReferencia;