import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import theme from './utils/theme';
import Routes from './Routes';
import { CssBaseline } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './assets/styles/styles.scss';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Routes />
      <ToastContainer />
      <CssBaseline />
    </ThemeProvider>
  );
}

export default App;
